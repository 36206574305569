import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import * as API from '../api/index';
import { toast } from 'react-toastify';
import { CheckCircle } from 'react-feather';
import { IMG } from '../api/constant';

const EditSong = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [imageData, setImageData] = useState('');
  const [catagoriData, setCatagoriData] = useState([]);
  const [fileName, setFileName] = useState('');
  const [thumFilename, setThumFilename] = useState('');
  const [songThumb, setSongThumb] = useState('');

  // Local state for form values
  const [formValues, setFormValues] = useState({
    name: '',
    category_id: '',
    subcategory_id: '2',
    description: '',
    minutes: '',
    second: '',
    amount: '',
    mood: '',
    genre: '',
    occasion: '',
  });

  console.log(formValues, 'formValues');
  // Function to handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const errors = {};

    // Simple validation checks
    if (!formValues.name) errors.name = 'Title is required';
    if (!formValues.occasion) errors.occasion = 'Occasion is required';
    if (!formValues.genre) errors.genre = 'Genre is required';
    if (!formValues.mood) errors.mood = 'Mood is required';
    if (!formValues.description) errors.description = 'Description is required';
    if (!formValues.amount) {
      errors.amount = 'Amount is required';
    } else {
      const amountValue = parseFloat(formValues.amount);
      if (!isNaN(amountValue)) {
        formValues.amount = amountValue.toFixed(2);
      } else {
        errors.amount = 'Amount must be a valid number';
      }
    }

    if (Object.keys(errors).length > 0) {
      for (const key in errors) {
        toast.error(errors[key]);
      }
      setIsLoading(false);
      return;
    }

    try {
      const token = localStorage.getItem('_tokenCode');
      const reqObj = {
        ...formValues,
        image: songThumb,
        id: location.state.id,
        filename: fileName || formValues.filename,
        thumFilename: thumFilename || formValues.thumFilename,
      };

      const response = await API.update_songs(reqObj, token);
      if (response.data.success) {
        toast.success(response.data.msg);
        navigate('/song-list');
      } else {
        toast.error(response.data.msg);
      }
    } catch (error) {
      toast.error('Failed to update song.');
    } finally {
      setIsLoading(false);
    }
  };

  const songsUploading = (e) => {
    let file = e.target.files[0];
    if (file.type === 'audio/mp3' || file.type === 'audio/mpeg') {
      setFileName(file.name);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageData(reader.result);
        setFormValues((prev) => ({ ...prev, music_file: reader.result }));
      };
      reader.readAsDataURL(file);
    } else {
      toast.error('Please upload only MP3 file.');
    }
  };

  const imageUploadingThum = (e) => {
    let file = e.target.files[0];
    if (file && (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg')) {
      setThumFilename(file.name);
      const reader = new FileReader();
      reader.onloadend = () => {
        setSongThumb(reader.result);
        setFormValues((prev) => ({ ...prev, image: reader.result }));
      };
      reader.readAsDataURL(file);
    } else {
      toast.error('Please upload only image files (JPEG, JPG, or PNG).');
    }
  };

  const commonDataTable = async () => {
    const token = localStorage.getItem('_tokenCode');
    try {
      const response = await API.getSongByid(location.state.id, token);
      setCatagoriData(response.data.data.categories);
      setFormValues(response.data.data);
      if (response.data.is_login === false) {
        localStorage.removeItem('isLogin');
        if (localStorage.getItem('isLogin') === null) {
          navigate('/');
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    commonDataTable();
  }, []);

  return (
    <div className='col-lg-12 layout-spacing'>
      <div className='statbox widget box box-shadow mb-4'>
        <div className='widget-header'>
          <div className='row'>
            <div className='col-xl-12 col-md-12 col-sm-12 col-12'>
              <h4>Edit Music</h4>
            </div>
          </div>
        </div>
        <div className='widget-content widget-content-area'>
          <form onSubmit={handleSubmit}>
            <div className='row'>
              <div className='col-md-6'>
                <div className='form-group'>
                  <label>
                    Title <span className='text-danger'>*</span>
                  </label>
                  <input type='text' name='name' className='form-control' placeholder='Enter here' value={formValues.name} onChange={handleChange} />
                  {formValues.name === '' ? <div className='text-danger'>{'Title is required'}</div> : null}
                </div>
              </div>
              <div className='col-md-6'>
                <div className='form-group'>
                  <label>
                    Occasion <span className='text-danger'>*</span>
                  </label>
                  <select name='occasion' className='form-control' value={formValues.occasion} onChange={handleChange}>
                    <option value=''>--- Select ---</option>
                    {catagoriData?.Occasion?.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                  {formValues.occasion === '' ? <div className='text-danger'>{'Occasion is required'}</div> : null}
                </div>
              </div>
              <div className='col-md-6'>
                <div className='form-group'>
                  <label>
                    Genre <span className='text-danger'>*</span>
                  </label>
                  <select name='genre' className='form-control' value={formValues.genre} onChange={handleChange}>
                    <option value=''>--- Select ---</option>
                    {catagoriData?.Genre?.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                  {formValues.genre === '' ? <div className='text-danger'>{'Genre is required'}</div> : null}
                </div>
              </div>
              <div className='col-md-6'>
                <div className='form-group'>
                  <label>
                    Mood <span className='text-danger'>*</span>
                  </label>
                  <select name='mood' className='form-control' value={formValues.mood} onChange={handleChange}>
                    <option value=''>--- Select ---</option>
                    {catagoriData?.Mood?.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                  {formValues.mood === '' ? <div className='text-danger'>{'Mood is required'}</div> : null}
                </div>
              </div>
              <div className='col-md-6'>
                <div className='form-group'>
                  <label>
                    Amount <span className='text-danger'>*</span>
                  </label>
                  <input type='number' name='amount' className='form-control' placeholder='Enter here' value={formValues.amount} onChange={handleChange} />
                  {formValues.amount === '' ? <div className='text-danger'>{'Amount is required'}</div> : null}
                </div>
              </div>
              <div className='col-md-6'>
                <div className='form-group'>
                  <label>
                    Song File <span className='text-danger'>*</span>
                  </label>
                  <div id='dropzone'>
                    <form encType='multipart/form-data' action='/upload' className='dropzone needsclick dz-clickable'>
                      <label htmlFor='fileT' className='dz-message needsclick'>
                        <div className='icon dripicons dripicons-browser-upload'></div>
                        <span className={imageData ? 'dz-button text-success' : 'dz-button'}>
                          {imageData ? (
                            <span className='d-inline-block mr-2'>
                              <CheckCircle color='green' size='30' />
                            </span>
                          ) : (
                            ''
                          )}
                          {imageData ? fileName : formValues.musicfile?.split('/').pop() || 'Upload MP3 files here'}
                        </span>
                      </label>
                      <input hidden type='file' id='fileT' onChange={songsUploading} className='image-preview-filepond' />
                    </form>
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='form-group'>
                  <label>
                    Thumbnail <span className='text-danger'>*</span>
                  </label>
                  <div id='dropzone'>
                    <form encType='multipart/form-data' action='/upload' className='dropzone needsclick dz-clickable'>
                      <label htmlFor='file' className='dz-message needsclick'>
                        <div className='icon dripicons dripicons-browser-upload'></div>
                        <span className={songThumb ? 'dz-button text-success' : 'dz-button'}>
                          {songThumb ? (
                            <span className='d-inline-block mr-2'>
                              <CheckCircle color='green' size='30' />
                            </span>
                          ) : (
                            ''
                          )}
                          {songThumb ? thumFilename : formValues.image?.split('/').pop() || 'Upload thumbnail files here'}
                        </span>
                      </label>
                      <input hidden type='file' id='file' onChange={imageUploadingThum} className='image-preview-filepond' />
                    </form>
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <img className='thumImg' src={songThumb || `${IMG}${formValues.image}`} alt='Thumbnail' />
              </div>
              <div className='col-md-6'>
                <div className='form-group'>
                  <label>
                    Description <span className='text-danger'>*</span>
                  </label>
                  <textarea name='description' className='form-control' placeholder='Enter here' value={formValues.description} onChange={handleChange} />
                  {formValues.description === '' ? <div className='text-danger'>{'Description is required'}</div> : null}
                </div>
              </div>
              <div className='col-md-12'>
                <div className='form-group text-right'>
                  <button type='submit' className='btn btn-primary' disabled={isLoading}>
                    {isLoading ? 'Updating...' : 'Update'}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditSong;
