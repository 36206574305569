import React from 'react';
import { Trash2 } from 'react-feather';

const OrderTable = ({ data, title, setShowModal, setDeleteOrderId }) => {
  console.log('data: ', data);
  return (
    <>
      <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing'>
        <div className='widgets ecommerce-tables'>
          <div className='widget-contents'>
            <div className='table-responsive'>
              <table className='table table-hover'>
                <thead>
                  <tr>
                    <th>
                      <div className='th-content'>Order Id</div>
                    </th>
                    <th>
                      <div className='th-content'>Client</div>
                    </th>
                    <th>
                      <div className='th-content'>Song Name</div>
                    </th>
                    {/* <th>
                      <div className='th-content'>Template Name</div>
                    </th> */}
                    <th>
                      <div className='th-content'>Amount</div>
                    </th>
                    <th className='text-center'>
                      <div className='th-content'>Fulfillment Status</div>
                    </th>
                    <th>
                      <div className='th-content'>Action</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data?.length !== 0 ? (
                    data?.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1} </td>
                        <td>{item.user}</td>
                        <td>{item.song_name}</td>
                        {/* <td>{item.templatename}</td> */}
                        <td>$ {item.amount}</td>
                        <td className='text-center'>
                          <span className={`badge ${item.is_paid ? 'badge-success' : 'badge-info'}`} style={{ width: '160px' }}>
                            {item.is_paid ? 'Completed' : 'In-Completed'}
                          </span>
                        </td>
                        <td>
                          <div className='d-flex justify-content-center'>
                            {/* <button
                              type='button'
                              onClick={() => {
                                setDeleteOrderId(item.id);
                                setShowModal(true);
                              }}
                              className='align-items-center btn btn-danger d-flex font-20 px-2'
                            >
                              <i className='las la-times-circle'></i>
                            </button> */}

                            <button
                              type='button'
                              onClick={() => {
                                setDeleteOrderId(item.id);
                                setShowModal(true);
                              }}
                              className='align-items-center btn btn-danger d-flex font-20 px-2'
                            >
                              <Trash2 size={20} />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className='text-center'>
                      <td colSpan={6} style={{ fontSize: '25px' }}>
                        No Record found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderTable;
