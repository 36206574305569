import React from "react";
import Header from "./Header";
import SubHeader from "./SubHeader";
import Dashboard from "./Dashboard";

const Container = () => {
  return (
    <>
      <Dashboard />
    </>
  );
};

export default Container;
