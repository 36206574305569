import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const SubHeader = () => {
  const location = useLocation();

  const breadcrumbMap = {
    '/dashboard': 'Dashboard',
    '/user': 'User List',
    '/order': 'Total Orders',
    '/song-list': 'All Songs',
    '/categories': 'Categories',
    '/payment-history': 'Payment History',
    '/about-list': 'About Us',
    '/homepage-list': 'Home Page',
    '/faq-list': 'Faq',
    '/contact-us': 'Enquiry',
  };

  const breadcrumbText = breadcrumbMap[location?.pathname] || '';

  return (
    <div className='sub-header-container'>
      <header className='header navbar navbar-expand-sm justify-content-between'>
        <a href='javascript:void(0);' className='sidebarCollapse' data-placement='bottom'></a>
        <ul className='navbar-nav flex-row'>
          <li>
            <div className='page-header'>
              <nav className='breadcrumb-one' aria-label='breadcrumb'>
                <ol className='breadcrumb'>
                  <li className='breadcrumb-item'>
                    <Link to='/dashboard'>Dashboard</Link>
                  </li>
                  {breadcrumbText && (
                    <li className='breadcrumb-item active' aria-current='page'>
                      <span>{breadcrumbText}</span>
                    </li>
                  )}
                </ol>
              </nav>
            </div>
          </li>
        </ul>
      </header>
    </div>
  );
};

export default SubHeader;
