import React from 'react';
import { Edit, Trash2 } from 'react-feather';
import * as API from '../../api/index';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';
import { MESSAGE, header } from '../../schemas/Validation';

const TempleteListing = ({ setIsLogin }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  useEffect(() => {
    commonDataTable();
  }, [location.state?.callAPI]);

  const commonDataTable = async () => {
    const header = localStorage.getItem('_tokenCode');
    try {
      const response = await API.template_listing(location.state.id, header);
      setData(response.data);
      if (response.data.is_login === false) {
        localStorage.removeItem('isLogin');
        setIsLogin(localStorage.removeItem('isLogin'));
        if (localStorage.getItem('isLogin') === null) {
          navigate('/');
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // const userDelete = async (songId) => {
  //   try {
  //     const response = await API.tem_delete(songId, header);
  //     if (response.data.success === 1) {
  //       commonDataTable();
  //       MESSAGE(response.data.msg);
  //     }
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   }
  // };

  const handleDelete = (id) => {
    setDeleteId(id);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  const confirmDelete = async () => {
    try {
      const response = await API.tem_delete(deleteId, header);
      if (response.data.success === 1) {
        commonDataTable();
        MESSAGE(response.data.msg);
        setDeleteId(null);
      }
    } catch (error) {
      console.error('Error deleting FAQ', error);
    } finally {
      setShowModal(false);
    }
  };

  return (
    <>
      <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing'>
        <div class='widget ecommerce-table'>
          <div class='widget-heading'>
            <div class='row'>
              <div class='col-xl-10 col-md-10 col-sm-10 col-12'>
                <h5 class=''>List of Voice Over Template </h5>
              </div>
              <div className='col-md-2 text-lg-right'></div>
            </div>
          </div>
          <div class='widget-content'>
            <div class='table-responsive'>
              <table class='table table-hover table-bordered'>
                <thead>
                  <tr>
                    <th>
                      <div class='th-content'>ID</div>
                    </th>
                    <th>
                      <div class='th-content'> Name</div>
                    </th>
                    <th>
                      <div class='th-content'>Template Type</div>
                    </th>
                    <th>
                      <div class='th-content'>Amount</div>
                    </th>
                    <th>
                      <div class='th-content'>Action</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.length === 0 ? (
                    <tr className='text-center'>
                      <td colSpan={5} style={{ fontSize: '25px' }}>
                        No Record found
                      </td>
                    </tr>
                  ) : (
                    data.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1} </td>
                        <td> {item.name}</td>
                        <td>
                          <p>{item?.templeteType}</p>
                        </td>
                        <td>
                          <p>$ {item.amount}</p>
                        </td>

                        <td width='80'>
                          <div className='d-flex justify-content-center'>
                            <Link state={{ id: location.state.id, childId: item.id }} to='/edit-template' className='btn btn-info d-flex font-20 px-2 mr-2'>
                              <Edit />
                            </Link>

                            <button type='button' onClick={() => handleDelete(item.id)} class='align-items-center btn btn-danger d-flex font-20 px-2'>
                              <Trash2 size={20} />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {showModal && (
        <div className='sharable-popup'>
          <div className='sharable-popup-content text-center' style={{ width: '500px' }}>
            <span className='sharable-close' onClick={closeModal}>
              &times;
            </span>
            <p style={{ margin: '20px 0', color: '#000000' }}>Are you sure you want to delete this voice over template?</p>
            <div className='d-flex justify-content-center align-items-center'>
              <button className='border-0 delete-btn' style={{ marginRight: '15px' }} onClick={confirmDelete}>
                Delete
              </button>
              <button className='border-0 cancel-btn' onClick={closeModal}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TempleteListing;
