import React from 'react';
import { useState } from 'react';
import * as API from '../api/index';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { MESSAGE, header } from '../schemas/Validation';
const initialData = {
  title: '',
  logo: '',
  video: '',
  message: '',
};
const EditAbout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [formData, setFormData] = useState(initialData);
  const [imageData, setImageData] = useState('');
  const [video, setVideo] = useState('');

  const handalerChanges = (e) => {
    const { name, value } = e.target;
    if (name === 'logo') {
      let images = e.target.files[0];
      if (images && (images.type === 'image/jpeg' || images.type === 'image/png' || images.type === 'image/jpg')) {
        setImageData(images);
      } else {
        toast.error('Please upload only image files (JPEG,JPG or PNG).');
        return;
      }
    }
    setFormData({ ...formData, [name]: value });
  };
  const handalerChangesv = (e) => {
    const file = e.target.files[0];
    console.log('file: ', file);

    if (file && (file.type === 'video/mp4' || file.type === 'video/webm')) {
      setVideo(file);
    } else {
      toast.error('Invalid file type. Please select a valid video file in MP4 or WEBM format.');
      return;
    }
  };

  const add_subcatagori = async () => {
    const formSubmitData = new FormData();
    formSubmitData.append('logo', imageData);
    formSubmitData.append('video', video);
    formSubmitData.append('message', formData.message);
    formSubmitData.append('title', formData.title);

    try {
      const response = await API.aboutusUpdate(formSubmitData, header, location.state.id);
      if (response.data.success === 1) {
        navigate('/about-list');
        MESSAGE(response.data.msg, 1);
      } else {
        MESSAGE(response.data.msg);
      }
    } catch (error) {}
  };

  //const btnDisabal = !formData.name || !formData.details || !imageData;
  const get_categorisByid = async () => {
    try {
      const response = await API.aboutusGet(header);
      setFormData(response.data.data);
    } catch (error) {
      console.error('Error fetching category data:', error);
    }
  };
  useEffect(() => {
    get_categorisByid();
  }, []);

  return (
    <>
      <div className='col-lg-12 layout-spacing'>
        <div className='statbox widget box box-shadow mb-4'>
          <div className='widget-header'>
            <div className='row'>
              <div className='col-xl-12 col-md-12 col-sm-12 col-12'>
                <h4>Edit About</h4>
              </div>
            </div>
          </div>
          <div className='widget-content widget-content-area'>
            <div className='row'>
              <div className='col-md-6'>
                <div className='form-group'>
                  <label>
                    Title
                    <span className='text-danger'>*</span>
                  </label>
                  <input type='text' className='form-control' placeholder='Enter here' value={formData.title} name='title' onChange={handalerChanges} />
                </div>
              </div>
              <div className='col-md-6'>
                {/* <div className='form-group'>
                  <label>
                    Logo
                    <span className='text-danger'>*</span>
                    <input type='file' name='logo' className='form-control' onChange={handalerChanges} />
                  </label>
                </div> */}

                <div className='form-group'>
                  <label>
                    Logo
                    <span className='text-danger'>*</span>
                  </label>
                  <div id='dropzone'>
                    <form encType='multipart/form-data' action='/upload' className='dropzone needsclick dz-clickable'>
                      <label for='file' className='dz-message needsclick'>
                        <div className='icon dripicons dripicons-browser-upload'></div>
                        <form encType='multipart/form-data'>
                          {imageData?.name ? (
                            <>
                              <span className='text-success'>{imageData.name}</span>
                            </>
                          ) : (
                            <>
                              <span className='dz-button'>{formData.logo.split('/').pop()}</span>
                            </>
                          )}
                          {!imageData.name && !formData.logo.split('/').pop() ? 'Upload files here' : ''}
                          <input hidden id='file' name='logo' type='file' onChange={handalerChanges} className='image-preview-filepond' />
                        </form>
                      </label>
                    </form>
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                {/* <div className='form-group'>
                  <label>
                    Video
                    <span className='text-danger'>*</span>
                  </label>
                  <input type='file' name='' className='form-control' onChange={handalerChangesv} />
                </div> */}

                <div className='form-group'>
                  <label>
                    Video
                    <span className='text-danger'>*</span>
                  </label>
                  <div id='dropzone'>
                    <form encType='multipart/form-data' action='/upload' className='dropzone needsclick dz-clickable'>
                      <label for='files' className='dz-message needsclick'>
                        <div className='icon dripicons dripicons-browser-upload'></div>
                        <form encType='multipart/form-data'>
                          {video.name ? (
                            <>
                              <span className='text-success'>{video.name}</span>
                            </>
                          ) : (
                            <>
                              <span className='dz-button'>{formData.video.split('/').pop()}</span>
                            </>
                          )}
                          {!video.name && !formData.video.split('/').pop() ? 'Upload files here' : ''}
                          <input hidden id='files' type='file' onChange={handalerChangesv} className='image-preview-filepond' />
                        </form>
                      </label>
                    </form>
                  </div>
                </div>
              </div>
              <div className='col-md-12'>
                <div className='form-group'>
                  <label>
                    Details
                    <span className='text-danger'>*</span>
                  </label>
                  <textarea value={formData.message} name='message' onChange={handalerChanges} placeholder='Enter hare' className='form-control' rows='10'></textarea>
                </div>
              </div>
            </div>
          </div>
          <div className='widget-footer text-right'>
            <button
              //disabled={btnDisabal ? true : false}
              onClick={add_subcatagori}
              type='reset'
              className='btn btn-success mr-2'
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditAbout;
