import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as API from '../api/index';
import { MESSAGE } from '../schemas/Validation';
import { toast } from 'react-toastify';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const EditFaq = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    question: '',
    answer: '',
  });
  const [editorValue, setEditorValue] = useState('');
  const [errors, setErrors] = useState({
    question: '',
    answer: '',
  });
  console.log('errors: ', errors);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const token = localStorage.getItem('_tokenCode');

    try {
      const response = await API.getFaq_id(location.state.id, token);
      console.log('response: ', response);

      if (response.data.is_login === false) {
        localStorage.removeItem('isLogin');
        navigate('/');
      } else {
        setFormData(response.data.data);
        setEditorValue(response.data.data.answer); // Set initial value for editor
      }
    } catch (error) {
      // Handle error
    }
  };

  const handleQuillChange = (value) => {
    setEditorValue(value); // Update editor value
  };
  const stripHtmlTags = (html) => {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent || div.innerText || '';
  };

  const validateForm = () => {
    let valid = true;
    const errors = {
      question: '',
      answer: '',
    };

    if (!formData.question.trim()) {
      errors.question = 'Question is required';
      valid = false;
    }

    if (!stripHtmlTags(editorValue).trim()) {
      errors.answer = 'Answer is required';
      valid = false;
    }

    setErrors(errors);
    return valid;
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    setIsLoading(true);
    const token = localStorage.getItem('_tokenCode');

    try {
      const reqObj = {
        question: formData.question,
        answer: editorValue,
      };

      console.log('reqObj: ', reqObj);
      const response = await API.edit_faq(location.state.id, reqObj, token);

      if (response.data.success === 1) {
        MESSAGE(response.data.msg, 1);
        navigate('/faq-list');
        setFormData(response.data.data);
      } else {
        toast.error(response.data.msg);
      }
    } catch (error) {
      toast.error('Something went wrong!');
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className='col-lg-12 layout-spacing'>
      <div className='statbox widget box box-shadow mb-4'>
        <div className='widget-header'>
          <div className='row'>
            <div className='col-xl-12 col-md-12 col-sm-12 col-12'>
              <h4>Edit Faq</h4>
            </div>
          </div>
        </div>

        <div className='widget-content widget-content-area'>
          <div className='row'>
            <div className='normal'>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <label>
                          Title
                          <span className='text-danger'>*</span>
                        </label>
                        <input type='text' className='form-control' placeholder='Enter here Question' value={formData.question} name='question' onChange={handleInputChange} />
                        {errors.question && <div className='text-danger'>{errors.question}</div>}
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <label>
                          Description
                          <span className='text-danger'>*</span>
                        </label>
                        <ReactQuill
                          value={editorValue}
                          theme='snow'
                          onChange={handleQuillChange}
                          placeholder='Enter here Answer'
                          modules={{
                            toolbar: {
                              container: [
                                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                                ['bold', 'italic', 'underline', 'strike'],
                                [{ color: [] }, { background: [] }],
                                [{ font: [] }],
                                [{ align: [] }],
                                [{ list: 'ordered' }, { list: 'bullet' }],
                                ['clean'],
                              ],
                            },
                          }}
                        />
                        {errors.answer && <div className='text-danger'>{errors.answer}</div>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='widget-footer'>
          <button onClick={handleSubmit} type='button' className={`btn ${isLoading ? 'btn-info' : 'btn-success'} mr-2`} disabled={isLoading}>
            {isLoading ? 'Loading...' : 'Update'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditFaq;
