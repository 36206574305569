import React from 'react';
import * as API from '../../api/index';
import { useEffect } from 'react';
import { useState } from 'react';

import { MESSAGE, header } from '../../schemas/Validation';
import { useNavigate } from 'react-router';
const ContactList = ({ setIsLogin }) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [deleteEnquiryId, setDeleteEnquiryId] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    orderDataTable();
  }, []);

  const orderDataTable = async () => {
    const token = localStorage.getItem('_tokenCode');
    try {
      const response = await API.contactGet(token);
      setData(response.data.data);

      if (response.data.is_login === false) {
        localStorage.removeItem('isLogin');
        setIsLogin(false);
        navigate('/');
      }
    } catch (error) {
      console.error('Error fetching order data:', error);
    }
  };

  const handleEnquiryDelete = (id) => {
    setDeleteEnquiryId(id);
    setShowModal(true);
  };

  const confirmDelete = async () => {
    try {
      const response = await API.deleteEnquiryContact(deleteEnquiryId, header);
      if (response.data.success === 1) {
        orderDataTable();
        MESSAGE(response.data.msg, 1);
        setDeleteEnquiryId(null);
      }
    } catch (error) {
      console.error('Error deleting FAQ', error);
    } finally {
      setShowModal(false);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing'>
        <div className='widget ecommerce-table'>
          <div className='widget-heading'>
            <h5 className=''>Enquiry list </h5>
          </div>
          <div className='widget-content'>
            <div className='table-responsive'>
              <table className='table table-hover'>
                <thead>
                  <tr>
                    <th>
                      <div className='th-content'>ID</div>
                    </th>
                    <th>
                      <div className='th-content'>Name</div>
                    </th>
                    <th>
                      <div className='th-content'>Email</div>
                    </th>
                    <th>
                      <div className='th-content'>Phone No</div>
                    </th>
                    <th>
                      <div className='th-content'>Address</div>
                    </th>
                    <th>
                      <div className='th-content'>Details</div>
                    </th>
                    <th className='text-center'>
                      <div className='th-content'>Action</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data?.length !== 0 ? (
                    data?.map((item, index) => (
                      <tr>
                        <td>{index + 1} </td>
                        <td>{item.name}</td>
                        <td>{item.email}</td>
                        <td>{item.phone === '' ? 'N/A' : item.phone}</td>
                        <td>{item.address}</td>
                        <td>{item.message}</td>
                        <td>
                          <div className='d-flex justify-content-center'>
                            <button type='button' onClick={() => handleEnquiryDelete(item.id)} className='align-items-center btn btn-danger d-flex font-20 px-2'>
                              <i className='las la-times-circle'></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className='text-center'>
                      <td colSpan='7' style={{ fontSize: '25px' }}>
                        No Record found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {showModal && (
        <div className='sharable-popup'>
          <div className='sharable-popup-content text-center' style={{ width: '500px' }}>
            <span className='sharable-close' onClick={closeModal}>
              &times;
            </span>
            <p style={{ margin: '20px 0', color: '#000000' }}>Are you sure you want to delete this Enquiry?</p>
            <div className='d-flex justify-content-center align-items-center'>
              <button className='border-0 delete-btn' style={{ marginRight: '15px' }} onClick={confirmDelete}>
                Delete
              </button>
              <button className='border-0 cancel-btn' onClick={closeModal}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ContactList;
