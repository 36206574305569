import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { toast } from 'react-toastify';
import * as API from '../api/index';
import { MESSAGE, header } from '../schemas/Validation';

const initialData = {
  title: '',
  video: '',
  message: '',
};

const EditHomepage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [formData, setFormData] = useState(initialData);
  const [video, setVideo] = useState('');
  const [videoName, setVideoName] = useState('');

  const handleChanges = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleVideoChange = (e) => {
    const file = e.target.files[0];

    if (file && (file.type === 'video/mp4' || file.type === 'video/webm')) {
      setVideo(file);
      setVideoName(file.name);
    } else {
      toast.error('Invalid file type. Please select a valid video file in MP4 or WEBM format.');
      return;
    }
  };

  const updateHomepage = async () => {
    const formSubmitData = new FormData();
    formSubmitData.append('video', video);
    formSubmitData.append('message', formData.message);
    formSubmitData.append('title', formData.title);

    try {
      const response = await API.Updatehomepage(formSubmitData, header, location.state.id);
      if (response.data.success === 1) {
        navigate('/homepage-list');
        MESSAGE(response.data.msg, 1);
      } else {
        MESSAGE(response.data.msg);
      }
    } catch (error) {
      console.error('Error updating homepage:', error);
      toast.error('Failed to update homepage. Please try again later.');
    }
  };

  const getCategoryById = async () => {
    try {
      const response = await API.Gethomepage(header);
      setFormData(response.data.data);
    } catch (error) {
      console.error('Error fetching category data:', error);
    }
  };

  useEffect(() => {
    getCategoryById();
  }, [location.state.id]);

  return (
    <>
      <div className='col-lg-12 layout-spacing'>
        <div className='statbox widget box box-shadow mb-4'>
          <div className='widget-header'>
            <div className='row'>
              <div className='col-xl-12 col-md-12 col-sm-12 col-12'>
                <h4>Edit Homepage</h4>
              </div>
            </div>
          </div>
          <div className='widget-content widget-content-area'>
            <div className='row'>
              <div className='col-md-6'>
                <div className='form-group'>
                  <label>
                    Title
                    <span className='text-danger'>*</span>
                  </label>
                  <input type='text' className='form-control' placeholder='Enter title here' value={formData.title} name='title' onChange={handleChanges} />
                </div>
              </div>
              <div className='col-md-6'>
                {/* <div className='form-group'>
                  <label>
                    Video
                    <span className='text-danger'>*</span>
                  </label>
                  <input type='file' className='form-control' onChange={handleVideoChange} />
                </div> */}
                <div class='form-group'>
                  <label>
                    Video
                    <span class='text-danger'>*</span>
                  </label>
                  <div id='dropzone'>
                    <form encType='multipart/form-data' action='/upload' class='dropzone needsclick dz-clickable'>
                      <label for='file' className='dz-message needsclick'>
                        <div class='icon dripicons dripicons-browser-upload'></div>{' '}
                        <form encType='multipart/form-data'>
                          {videoName ? (
                            <>
                              <span className='text-success'>{videoName}</span>
                            </>
                          ) : (
                            <>
                              <span class='dz-button'>{formData.video.split('/').pop()}</span>
                            </>
                          )}
                          {!videoName && !formData.video.split('/').pop() ? 'Upload files here' : ''}
                          <input hidden id='file' type='file' onChange={handleVideoChange} class='image-preview-filepond' />
                        </form>
                      </label>
                    </form>
                  </div>
                </div>
              </div>
              <div className='col-md-12'>
                <div className='form-group'>
                  <label>
                    Details
                    <span className='text-danger'>*</span>
                  </label>
                  <textarea value={formData.message} name='message' onChange={handleChanges} placeholder='Enter details here' className='form-control' rows='10'></textarea>
                </div>
              </div>
            </div>
          </div>
          <div className='widget-footer text-right'>
            <button onClick={updateHomepage} type='button' className='btn btn-success mr-2'>
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditHomepage;
