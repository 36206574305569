import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Edit2, PlusCircle, Trash2 } from 'react-feather';
import * as API from '../api/index';
import { toast } from 'react-toastify';
import { IMG } from '../api/constant';
import Songtable from './Songtable';

const HomepageList = ({ setIsLogin }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    commonDataTable();
  }, []);

  // Fetch homepage data
  const commonDataTable = async () => {
    const header = localStorage.getItem('_tokenCode');
    try {
      const response = await API.Gethomepage(header);
      if (response.data.data) {
        setData(response.data.data);
      }
    } catch (error) {
      toast.error('Error fetching data');
      console.error('Error fetching data:', error);
    }
  };

  return (
    <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing'>
      <div className='widget ecommerce-table'>
        <div className='widget-heading'>
          <div className='row'>
            <div className='col-xl-10 col-md-10 col-sm-10 col-12'>
              <h5>Homepage</h5>
            </div>
            <div className='col-md-2 text-lg-right'>
              {!data ? (
                <Link to='/about' type='button' className='btn btn-success font-20'>
                  <i className='las la-plus'></i>
                </Link>
              ) : null}
            </div>
          </div>
        </div>
        <div className='widget-content'>
          <div className='table-responsive'>
            <table className='table table-hover table-bordered'>
              <thead>
                <tr>
                  <th>
                    <div className='th-content'>Title</div>
                  </th>
                  <th>
                    <div className='th-content'>Video</div>
                  </th>
                  <th>
                    <div className='th-content'>Details</div>
                  </th>
                  <th>
                    <div className='th-content text-center'>Action</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {data && (
                  <tr>
                    <td width='150'>{data.title}</td>
                    <td width='150'>
                      <video src={`${IMG}${data.video}`} controls width='100%' style={{ height: '150px' }}></video>
                    </td>
                    <td width='400'>{data.message}</td>
                    <td width='80'>
                      <div className='d-flex justify-content-center'>
                        <Link state={{ id: data.id }} to='/edit-homepage' className='align-items-center mr-2 btn btn-success d-flex font-20 px-2'>
                          <Edit2 size={20} />
                        </Link>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomepageList;
