import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import * as API from '../api/index';
import { toast } from 'react-toastify';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const initialData = {
  question: '',
  answer: '',
};

const validationSchema = Yup.object({
  question: Yup.string().required('Question is required'),
  answer: Yup.string().required('Answer is required'),
});

const AddFaq = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const addFaq = async (values) => {
    const token = localStorage.getItem('_tokenCode');
    setIsLoading(true);
    try {
      const reqObj = { question: values.question, answer: values.answer };
      const response = await API.add_faq(reqObj, token);
      if (response.data.success === 1) {
        toast.success(response.data.msg);
        navigate('/faq-list');
      } else {
        toast.error(response.data.msg);
        setIsLoading(false);
      }
    } catch (error) {
      toast.error('Something went wrong!');
      setIsLoading(false);
    }
  };

  return (
    <Formik initialValues={initialData} validationSchema={validationSchema} onSubmit={addFaq}>
      {({ values, setFieldValue, errors, touched }) => (
        <Form>
          <div className='col-lg-12 layout-spacing'>
            <div className='statbox widget box box-shadow mb-4'>
              <div className='widget-header'>
                <div className='row'>
                  <div className='col-12'>
                    <h4>Add FAQ</h4>
                  </div>
                </div>
              </div>
              <div className='widget-content widget-content-area'>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group position-relative mb-4'>
                      <label>
                        Question <span className='text-danger'>*</span>
                      </label>
                      <Field type='text' className='form-control' placeholder='Enter here Question' name='question' />
                      <ErrorMessage name='question' component='div' className='text-danger error-msg' />
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group position-relative mb-4'>
                      <label>
                        Answer <span className='text-danger'>*</span>
                      </label>
                      <ReactQuill
                        value={values.answer}
                        onChange={(val) => setFieldValue('answer', val)}
                        placeholder='Enter here Answer'
                        className=''
                        modules={{
                          toolbar: {
                            container: [
                              [{ header: [1, 2, 3, 4, 5, 6, false] }],
                              ['bold', 'italic', 'underline', 'strike'],
                              [{ color: [] }, { background: [] }],
                              [{ font: [] }],
                              [{ align: [] }],
                              [{ list: 'ordered' }, { list: 'bullet' }],
                              ['clean'],
                            ],
                          },
                        }}
                      />
                      {errors.answer && touched.answer && <div className='text-danger error-msg'>{errors.answer}</div>}
                    </div>
                  </div>
                </div>
              </div>
              <div className='widget-footer'>
                <button type='submit' className='btn btn-success mr-2' disabled={isLoading}>
                  {isLoading ? 'Loading...' : 'Submit'}
                </button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddFaq;
