import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from '../page/Login';
import Base from '../components/Base';
import Order from '../page/Order';
import SidBar from '../components/SidBar';
import Header from '../components/Header';
import SubHeader from '../components/SubHeader';
import UserList from '../page/UserList';
import Categories from '../page/Categories';
import AddCategoris from '../page/AddCategoris';
import Song from '../page/Song';
import AddSong from '../page/AddSong';
import EditCategoris from '../page/EditCategoris';
import Script from '../page/Script';
import AddScript from '../page/AddScript';
import SongTemplete from '../page/Song/SongTemplete';
import TempleteListing from '../page/Song/TempleteListing';
import PaymentHistory from '../page/PaymentHistory';
import EditSong from '../page/EditSong';
import EditTemplate from '../page/Song/EditTemplate';
import Testimonials from '../page/Testimonials/Testimonials';
import AddTestimonials from '../page/Testimonials/AddTestimonials';
import About from '../page/About';
import AboutList from '../page/AboutList';
import EditAbout from '../page/EditAbout';
import ContactList from '../page/Contact/ContactList';
import Faqs from '../page/Faqs';
import EditFaq from '../page/EditFaq';
import AddFaq from '../page/AddFaq';
import HomepageList from '../page/HomepageList';
import EditHomepage from '../page/EditHomepage';
import EditUser from '../page/EditUser';

const AppRoute = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLogin, setIsLogin] = useState(localStorage.getItem('isLogin'));

  const toggleSidebar = () => setIsOpen(!isOpen);

  useEffect(() => {
    const loginStatus = localStorage.getItem('isLogin');
    setIsLogin(loginStatus);
  }, []);

  const protectedRoutes = [
    { path: '/', element: <Base /> },
    { path: '/dashboard', element: <Base /> },
    { path: '/order', element: <Order setIsLogin={setIsLogin} /> },
    { path: '/user', element: <UserList setIsLogin={setIsLogin} /> },
    { path: '/categories', element: <Categories setIsLogin={setIsLogin} /> },
    { path: '/add-categories', element: <AddCategoris /> },
    { path: '/song-list', element: <Song setIsLogin={setIsLogin} /> },
    { path: '/faq-list', element: <Faqs setIsLogin={setIsLogin} /> },
    { path: '/add-music', element: <AddSong /> },
    { path: '/edit-song', element: <EditSong /> },
    { path: '/add-faq', element: <AddFaq /> },
    { path: '/edit-faq', element: <EditFaq /> },
    { path: '/add-template', element: <SongTemplete /> },
    { path: '/edit-template', element: <EditTemplate /> },
    { path: '/template-list', element: <TempleteListing /> },
    { path: '/payment-history', element: <PaymentHistory /> },
    { path: '/script-list', element: <Script setIsLogin={setIsLogin} /> },
    { path: '/add-script', element: <AddScript /> },
    { path: '/edit-categories', element: <EditCategoris /> },
    { path: '/testimonials', element: <Testimonials /> },
    { path: '/about', element: <About /> },
    { path: '/about-list', element: <AboutList /> },
    { path: '/edit-about', element: <EditAbout /> },
    { path: '/homepage-list', element: <HomepageList /> },
    { path: '/edit-homepage', element: <EditHomepage /> },
    { path: '/add-testimonials', element: <AddTestimonials /> },
    { path: '/contact-us', element: <ContactList /> },
    { path: '/edit-user', element: <EditUser /> },
  ];

  return (
    <Router>
      {isLogin ? (
        <div className='main-container' id='container'>
          <div className='overlay'></div>
          <div className='search-overlay'></div>
          <div className='rightbar-overlay'></div>
          <SidBar isOpen={isOpen} />
          <div id='content' className='main-content'>
            <Header isOpen={isOpen} toggleSidebar={toggleSidebar} setIsLogin={setIsLogin} />
            <SubHeader />
            <Routes>
              {protectedRoutes.map((route, index) => (
                <Route key={index} path={route.path} element={route.element} />
              ))}
              <Route path='*' element={<Navigate to='/' />} />
            </Routes>
          </div>
        </div>
      ) : (
        <Routes>
          <Route path='/' element={<Login setIsLogin={setIsLogin} />} />
          <Route path='*' element={<Navigate to='/' />} />
        </Routes>
      )}
    </Router>
  );
};

export default AppRoute;
