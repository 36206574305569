import React, { useContext } from 'react';
import { Edit2, PlusCircle, Trash2 } from 'react-feather';
import * as API from '../api/index';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { header } from '../schemas/Validation';
import { toast } from 'react-toastify';
import { IMG } from '../api/constant';
import { AmountContext } from '../components/contexts/AmountContext';

const Song = ({ setIsLogin }) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [autoStopModal, setAutoStopModal] = useState(false);
  const [inputAutoStopValue, setInputAutoStopValue] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const { setAmount } = useContext(AmountContext);

  useEffect(() => {
    commonDataTable();
    getAutoStopValue();
  }, []);

  const commonDataTable = async () => {
    const header = localStorage.getItem('_tokenCode');
    try {
      const response = await API.song_listing(header);
      setData(response.data.data);
      if (response.data.is_login === false) {
        localStorage.removeItem('isLogin');
        setIsLogin(localStorage.removeItem('isLogin'));
        if (localStorage.getItem('isLogin') === null) {
          navigate('/');
        }
      }
    } catch (error) {}
  };

  const getAutoStopValue = async () => {
    try {
      const response = await API.get_autostop_song(header);
      console.log('response: ', response);
      if (response.data.success === 1) {
        setInputAutoStopValue(response.data.data.intro_outro_length);
      }
    } catch (error) {
      console.error('Error', error);
    }
  };

  const handleAmountClick = (id) => {
    const selectedItemRow = data.find((item) => item.id === id);
    setAmount(selectedItemRow.amount);
  };

  const handleDelete = (id) => {
    setDeleteId(id);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  const confirmDelete = async () => {
    try {
      const response = await API.song_delete(deleteId, header);
      if (response.data.success === 1) {
        commonDataTable();
        toast(response.data.msg, {
          position: 'top-right',
          autoClose: 5000,
          type: 'success',
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
        setDeleteId(null);
      }
    } catch (error) {
      console.error('Error deleting FAQ', error);
    } finally {
      setShowModal(false);
    }
  };

  const confirmAutoStop = async () => {
    try {
      const response = await API.patch_autostop_song({ intro_outro_length: inputAutoStopValue }, header);
      console.log('response: ', response);
      if (response.data.success === 1) {
        toast.success(response.data.msg);
        setInputAutoStopValue(null);
        setAutoStopModal(false);
        getAutoStopValue();
      }
    } catch (error) {
      console.error('Error deleting FAQ', error);
    }
  };

  return (
    <>
      <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing'>
        <div className='widget ecommerce-table'>
          <div className='widget-heading'>
            <div className='row justify-content-between align-items-center mx-3'>
              <div className=''>
                <h5 className=''>List of Music </h5>
              </div>
              <div className='d-flex'>
                <div className='mr-2'>
                  <div className='btn btn-success font-20' data-toggle='tooltip' data-placement='right' title='Auto Stop Intro Outro' onClick={() => setAutoStopModal(true)}>
                    <i class='bi bi-stopwatch'></i>
                  </div>
                </div>
                <div className=''>
                  <Link to='/add-music' type='button' data-toggle='tooltip' data-placement='right' title='Add Music' className='btn btn-success font-20'>
                    <i className='las la-plus'></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className='widget-content'>
            <div className='table-responsive'>
              <table className='table table-hover table-bordered'>
                <thead>
                  <tr>
                    <th>
                      <div className='th-content'>ID</div>
                    </th>
                    <th>
                      <div className='th-content'> Title</div>
                    </th>
                    <th>
                      <div className='th-content'>Details</div>
                    </th>
                    <th>
                      <div className='th-content'>Genre</div>
                    </th>
                    <th>
                      <div className='th-content'>Occasion</div>
                    </th>
                    <th>
                      <div className='th-content'>Mood</div>
                    </th>
                    <th>
                      <div className='th-content'>Amount</div>
                    </th>
                    <th>
                      <div className='th-content'>Thumbnail</div>
                    </th>
                    <th>
                      <div className='th-content'>Action</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.length === 0 ? (
                    // <h4 className='text-center'>No Record found</h4>
                    <tr className='text-center'>
                      <td colSpan={9} style={{ fontSize: '25px' }}>
                        No Record found
                      </td>
                    </tr>
                  ) : (
                    data.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1} </td>
                        <td width='150'> {item.name}</td>
                        <td width='400'>
                          <p>{item.description}</p>
                        </td>
                        <td width='200'>
                          <ul className='p-0 moodTag'>{item.genre === '' ? 'N/A' : item.genre}</ul>
                        </td>
                        <td width='200'>
                          <ul className='p-0 moodTag'>{item.occasion === '' ? 'N/A' : item.occasion}</ul>
                        </td>
                        <td width='200'>
                          <ul className='p-0 moodTag'>{item.mood === '' ? 'N/A' : item.mood}</ul>
                        </td>
                        <td>$ {item.amount}</td>
                        <td>
                          <img className='square-image' src={IMG + item.image} />
                        </td>
                        <td width='80'>
                          <div className='d-flex justify-content-center'>
                            <Link
                              state={{ id: item.id, amount: item.amount }}
                              to='/add-template'
                              onClick={() => handleAmountClick(item.id)}
                              className='align-items-center mr-2 btn btn-info d-flex font-20 px-2'
                            >
                              <PlusCircle size={20} />
                            </Link>
                            <Link state={{ id: item.id }} to='/edit-song' className='align-items-center mr-2 btn btn-success d-flex font-20 px-2'>
                              <Edit2 size={20} />
                            </Link>
                            <button type='button' onClick={() => handleDelete(item.id)} className='align-items-center btn btn-danger d-flex font-20 px-2'>
                              <Trash2 size={20} />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {showModal && (
        <div className='sharable-popup'>
          <div className='sharable-popup-content text-center' style={{ width: '500px' }}>
            <span className='sharable-close' onClick={closeModal}>
              &times;
            </span>
            <p style={{ margin: '20px 0', color: '#000000' }}>Are you sure you want to delete this Song?</p>
            <div className='d-flex justify-content-center align-items-center'>
              <button className='border-0 delete-btn' style={{ marginRight: '15px' }} onClick={confirmDelete}>
                Delete
              </button>
              <button className='border-0 cancel-btn' onClick={closeModal}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      {autoStopModal && (
        <div className='sharable-popup'>
          <div className='sharable-popup-content text-center' style={{ width: '500px' }}>
            <span className='sharable-close' onClick={() => setAutoStopModal(false)}>
              &times;
            </span>
            <span style={{ fontSize: '25px', fontWeight: 'bold' }}>Intro Outro Length</span>
            <div class='form-group mt-3'>
              <label className='text-left w-100 d-inline-block'>
                Intro Outro Length
                <span class='text-danger'>*</span>
              </label>
              <input
                type='text'
                class='form-control'
                placeholder='Enter '
                value={inputAutoStopValue}
                maxLength={3}
                onChange={(e) => {
                  if (isNaN(e.target.value)) {
                    return false;
                  } else {
                    setInputAutoStopValue(e.target.value);
                  }
                }}
              />
            </div>
            <div className='d-flex justify-content-center align-items-center'>
              <button
                className='border-0 delete-btn'
                style={{ marginRight: '15px', opacity: !inputAutoStopValue && '0.5', cursor: !inputAutoStopValue ? 'not-allowed' : 'pointer' }}
                disabled={!inputAutoStopValue}
                onClick={confirmAutoStop}
              >
                Save
              </button>
              <button className='border-0 cancel-btn' onClick={() => setAutoStopModal(false)}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Song;
