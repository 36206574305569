import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import * as API from '../api/index';
import { toast } from 'react-toastify';
import { IMG } from '../api/constant';

const EditUser = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
    address2: '',
    city: '',
    state: '',
    country: '',
    is_active: '',
    profile_image: null,
  });

  const [imagePreview, setImagePreview] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [allCountryData, setAllCountryData] = useState([]);
  const [allStateData, setAllStateData] = useState([]);
  const [allCityData, setAllCityData] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});

  const { name, email, phone, address, address2, city, state, country, profile_image } = userDetails;

  useEffect(() => {
    const fetchUserDetails = async () => {
      const header = localStorage.getItem('_tokenCode');
      try {
        const response = await API.get_user_by_id(location.state.id, header);
        const userData = response.data.data;

        setUserDetails(userData);
        if (userData.profile_image) {
          setImagePreview(IMG + userData.profile_image);
        }

        const cresponse = await API.allCountry(header);
        setAllCountryData(cresponse.data.data);

        if (userData.country) {
          const state_response = await API.allState(userData.country, header);
          setAllStateData(state_response.data.data);

          if (userData.state) {
            const city_response = await API.allCity(userData.state, header);
            setAllCityData(city_response.data.data);
          }
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    fetchUserDetails();
  }, [location.state.id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prev) => ({ ...prev, [name]: value }));
    setValidationErrors((prev) => ({ ...prev, [name]: '' })); // Clear any existing errors
  };

  const handleSelectChange = async (e) => {
    const { name, value } = e.target;
    const header = localStorage.getItem('_tokenCode');

    if (name === 'country') {
      const stateResponse = await API.allState(value, header);
      setAllStateData(stateResponse.data.data);
      setAllCityData([]);
      setUserDetails((prev) => ({
        ...prev,
        country: value,
        state: '',
        city: '',
      }));
    } else if (name === 'state') {
      const cityResponse = await API.allCity(value, header);
      setAllCityData(cityResponse.data.data);
      setUserDetails((prev) => ({
        ...prev,
        state: value,
        city: '',
      }));
    } else if (name === 'city') {
      setUserDetails((prev) => ({
        ...prev,
        city: value,
      }));
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
      if (!allowedTypes.includes(file.type)) {
        toast.error('Invalid file type. Please upload an image file.');
        return;
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result); // Set the preview to the uploaded image
      };
      reader.readAsDataURL(file);
      setImageFile(file);
    }
  };

  const handleUpdateUser = async () => {
    const errors = {};
    if (!name) errors.name = 'Name is required.';
    if (!email) errors.email = 'Email is required.';
    // if (!phone) errors.phone = 'Phone number is required.';
    // if (!address) errors.address = 'Address is required.';
    // if (!country) errors.country = 'Country is required.';
    // if (!state) errors.state = 'State is required.';
    // if (!city) errors.city = 'City is required.';

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }

    const header = localStorage.getItem('_tokenCode');
    const formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('phone', phone);
    formData.append('address', address);
    formData.append('address2', address2);
    formData.append('city', city);
    formData.append('state', state);
    formData.append('country', country);

    // Append image file if exists
    if (imageFile) {
      formData.append('profile_image', imageFile);
    }

    try {
      const response = await API.update_user(location.state.id, formData, header);
      console.log('response: ', response);

      if (response && response.status === 200) {
        toast.success('User updated successfully!');
        navigate('/user');
      } else {
        toast.error(response.data.msg);
      }
    } catch (error) {
      console.error('Error updating user:', error);
      toast.error('Error updating user. Please try again.');
    }
  };

  return (
    <div className='col-lg-12 layout-spacing'>
      <div className='statbox widget box box-shadow mb-4'>
        <div className='widget-header'>
          <h4>Edit User</h4>
        </div>
        <div className='widget-content widget-content-area'>
          <div className='position-relative mb-5'>
            <img
              src={imagePreview || (profile_image ? IMG + profile_image : 'https://cdn-icons-png.flaticon.com/512/3237/3237472.png')}
              className='profile-img'
              alt='Profile'
              style={{ width: '150px', height: '150px', borderRadius: '50%' }}
            />
            {/* <label htmlFor='imageUpload' className='edit-icon' title='Upload Image'>
              <i className='bi bi-pencil-square' style={{ fontSize: '25px' }}></i>
            </label>
            <input type='file' id='imageUpload' accept='image/*' onChange={handleImageChange} style={{ display: 'none' }} /> */}
          </div>
          <div className='row'>
            <div className='col-md-6'>
              <div className='form-group'>
                <label>
                  Name<span className='text-danger'>*</span>
                </label>
                <input type='text' className='form-control' placeholder='Enter here' value={name} onChange={handleInputChange} name='name' />
                {validationErrors.name && <small className='text-danger'>{validationErrors.name}</small>}
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group'>
                <label>
                  Email<span className='text-danger'>*</span>
                </label>
                <input type='email' className='form-control' placeholder='Enter here' value={email} onChange={handleInputChange} name='email' />
                {validationErrors.email && <small className='text-danger'>{validationErrors.email}</small>}
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group'>
                <label>Phone No</label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Enter here'
                  value={phone}
                  onChange={(e) => {
                    if (isNaN(e.target.value)) {
                      return false;
                    } else {
                      handleInputChange(e);
                    }
                  }}
                  maxLength={12}
                  name='phone'
                />
                {/* {validationErrors.phone && <small className='text-danger'>{validationErrors.phone}</small>} */}
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group'>
                <label>Address</label>
                <input type='text' className='form-control' placeholder='Enter here' value={address} onChange={handleInputChange} name='address' />
                {/* {validationErrors.address && <small className='text-danger'>{validationErrors.address}</small>} */}
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group'>
                <label>Address 2</label>
                <input type='text' className='form-control' placeholder='Enter here' value={address2} onChange={handleInputChange} name='address2' />
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group'>
                <label>
                  Country<span className='text-danger'>*</span>
                </label>
                <select className='form-control' value={country} onChange={handleSelectChange} name='country'>
                  <option value='' disabled>
                    Select Country
                  </option>
                  {allCountryData?.map((country) => (
                    <option key={country.id} value={country.id}>
                      {country.name}
                    </option>
                  ))}
                </select>
                {/* {validationErrors.country && <small className='text-danger'>{validationErrors.country}</small>} */}
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group'>
                <label>
                  State<span className='text-danger'>*</span>
                </label>
                <select className='form-control' value={state} onChange={handleSelectChange} name='state'>
                  <option value='' disabled>
                    Select State
                  </option>
                  {allStateData?.map((state) => (
                    <option key={state.id} value={state.id}>
                      {state.name}
                    </option>
                  ))}
                </select>
                {/* {validationErrors.state && <small className='text-danger'>{validationErrors.state}</small>} */}
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group'>
                <label>
                  City<span className='text-danger'>*</span>
                </label>
                <select className='form-control' value={city} onChange={handleSelectChange} name='city'>
                  <option value='' disabled>
                    Select City
                  </option>
                  {allCityData?.map((city) => (
                    <option key={city.id} value={city.id}>
                      {city.name}
                    </option>
                  ))}
                </select>
                {/* {validationErrors.city && <small className='text-danger'>{validationErrors.city}</small>} */}
              </div>
            </div>

            <div className='col-md-12'>
              <div className='form-group'>
                <button className='btn btn-primary' onClick={handleUpdateUser}>
                  Update User
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditUser;
