import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import * as API from '../api/index';
import { Edit2, Trash2 } from 'react-feather';
import { IMG } from '../api/constant';
import { toast } from 'react-toastify';
import { header } from '../schemas/Validation';

const Categories = () => {
  const [isActive, setIsActive] = useState(false);
  const [categoryData, setCategoryData] = useState('');
  const [categoryMain, setCategoryMain] = useState([]);
  const [deleteCategoryId, setDeleteCategoryId] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    getCategoryList();
    getAllSubCategories();
  }, []);

  const getCategoryList = async () => {
    const token = localStorage.getItem('_tokenCode');
    try {
      const response = await API.get_subCategory(token);
      setCategoryMain(response.data.data);
    } catch (error) {
      console.error('Error fetching category list:', error);
    }
  };

  const activateButton = () => {
    setIsActive(false);
    setCategoryData('');
    getAllSubCategories();
  };

  const getAllSubCategories = async () => {
    const token = localStorage.getItem('_tokenCode');
    try {
      const response = await API.getMain_subCategory(token);
      setTableData(response.data.data);
    } catch (error) {
      console.error('Error fetching all subcategories:', error);
    }
  };

  const categoryDetails = async (categoryId) => {
    const token = localStorage.getItem('_tokenCode');
    setIsActive(true);
    setCategoryData(categoryId);
    try {
      const response = await API.subCategoryId(categoryId, token);
      setTableData(response.data.data);
    } catch (error) {
      console.error('Error fetching category details:', error);
    }
  };

  const handleDelete = (id) => {
    setDeleteCategoryId(id);
    setShowModal(true);
  };

  const confirmDelete = async () => {
    try {
      const response = await API.subCategoryId_delete(deleteCategoryId, header);
      if (response.data.success === 1) {
        getAllSubCategories();
        toast(response.data.msg, {
          position: 'top-right',
          autoClose: 5000,
          type: 'success',
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
        setDeleteCategoryId(null);
      }
    } catch (error) {
      console.error('Error deleting FAQ', error);
    } finally {
      setShowModal(false);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <div id='tabsWithIcons' class='col-xl-12 col-lg-12 col-md-12 layout-spacing'>
        <div class='statbox widget box box-shadow'>
          <div class='widget-header'>
            <div class='row'>
              <div class='col-xl-10 col-md-10 col-sm-10 col-12'>
                <h4>Categories List</h4>
              </div>
              <div className='col-md-2 text-lg-right'>
                <Link to='/add-categories' type='button' class='btn btn-success font-20'>
                  <i class='las la-plus'></i>
                </Link>
              </div>
            </div>
          </div>
          <div class='widget-content catagoriy  widget-content-area rounded-corner-pills-icon'>
            <ul class='nav nav-pills mb-4 mt-3  justify-content-center' id='rounded-corner-pills-icon-tab' role='tablist'>
              <li class='nav-item ml-2 mr-2'>
                <span onClick={activateButton} class={isActive ? 'nav-link mb-2  text-center' : 'nav-link mb-2 active  text-center'}>
                  All
                </span>
              </li>
              {categoryMain.map((item, index) => (
                <li class='nav-item ml-2 mr-2' key={index}>
                  <span onClick={() => categoryDetails(item.id)} class={categoryData === item.id ? 'nav-link mb-2 active text-center' : 'nav-link mb-2 text-center'}>
                    {item.name}
                  </span>
                </li>
              ))}
            </ul>
            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing'>
              <div class='widget ecommerce-table'>
                <div class='widget-heading'>
                  <h5 class=''>List of Categories </h5>
                </div>
                <div class='widget-content'>
                  <div class='table-responsive text-center'>
                    <table class='table table-hover table-bordered'>
                      <thead>
                        <tr>
                          <th>
                            <div class='th-content'>ID</div>
                          </th>
                          <th>
                            <div class='th-content'> Title</div>
                          </th>
                          <th>
                            <div class='th-content'>Details</div>
                          </th>
                          <th>
                            <div class='th-content'>Image</div>
                          </th>
                          <th>
                            <div class='th-content'>Action</div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData?.length !== 0 ? (
                          tableData.map((item, index) => (
                            <tr>
                              <td>{index + 1}</td>
                              <td>{item.name}</td>
                              <td>{item.details}</td>
                              <td>
                                <img style={{ height: '100px' }} src={IMG + item.image} />
                              </td>
                              <td>
                                <div className='d-flex justify-content-center'>
                                  <Link
                                    to='/edit-categories'
                                    state={{ id: item.id }}
                                    //onClick={() => userStatus(item.id)}
                                    class='align-items-center mr-2 btn btn-success d-flex font-20 px-2'
                                  >
                                    <Edit2 size={20} />
                                  </Link>
                                  {/* <button type='button' onClick={() => handleDelete(item.id)} class='align-items-center btn btn-danger d-flex font-20 px-2'>
                                    <i class='las la-times-circle'></i>
                                  </button> */}

                                  <button type='button' onClick={() => handleDelete(item.id)} className='align-items-center btn btn-danger d-flex font-20 px-2'>
                                    <Trash2 size={20} />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr className='text-center'>
                            <td colSpan='5' style={{ fontSize: '25px' }}>
                              No Record found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showModal && (
        <div className='sharable-popup'>
          <div className='sharable-popup-content text-center' style={{ width: '500px' }}>
            <span className='sharable-close' onClick={closeModal}>
              &times;
            </span>
            <p style={{ margin: '20px 0', color: '#000000' }}>Are you sure you want to delete this Category?</p>
            <div className='d-flex justify-content-center align-items-center'>
              <button className='border-0 delete-btn' style={{ marginRight: '15px' }} onClick={confirmDelete}>
                Delete
              </button>
              <button className='border-0 cancel-btn' onClick={closeModal}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Categories;
