import React from "react";
import SidBar from "./SidBar";
import Container from "./Container";

const Base = () => {
  return (
    <>
      <Container />
    </>
  );
};

export default Base;
