import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import * as API from '../api/index';
import { MESSAGE, header } from '../schemas/Validation';
import { toast } from 'react-toastify';
import { CheckCircle } from 'react-feather';

const initialData = {
  name: '',
  category_id: '',
  subcategory_id: '2',
  music_file: '',
  description: '',
  minutes: '',
  second: '',
  amount: '',
  genre: '',
  occasion: '',
  mood: '',
};

const validationSchema = Yup.object({
  name: Yup.string().required('Title is required'),
  occasion: Yup.string().required('Occasion is required'),
  genre: Yup.string().required('Genre is required'),
  mood: Yup.string().required('Mood is required'),
  amount: Yup.number().required('Amount is required').positive('Amount must be positive'),
  music_file: Yup.string().required('Music file is required'),
  description: Yup.string().required('Description is required'),
});

const AddSong = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [imageData, setImageData] = useState('');
  const [catagoriData, setCatagoriData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [searchData2, setSearchData2] = useState([]);
  const [searchData3, setSearchData3] = useState([]);
  const [fileName, setFileName] = useState('');
  const [thumFilename, setThumFilename] = useState('');
  const [songThumb, setSongThumb] = useState('');

  const imageUploading = (e, setFieldValue) => {
    let file = e.target.files[0];
    if (file && file.type === 'audio/mpeg') {
      setFileName(file.name);
      var reader = new FileReader();
      reader.onloadend = function () {
        setImageData(reader.result);
        setFieldValue('music_file', reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      toast.error('Please upload only MP3 files');
    }
  };

  const imageUploadingThum = (e, setFieldValue) => {
    let file = e.target.files[0];
    if (file && file.type.startsWith('image/')) {
      setThumFilename(file.name);
      var reader = new FileReader();
      reader.onloadend = function () {
        setSongThumb(reader.result);
        setFieldValue('thumbnail_image', reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      toast.error('Please upload only image files (JPEG,JPG or PNG).');
    }
  };

  const get_categoryList = async () => {
    try {
      const response = await API.get_subCategory(header);
      setCatagoriData(response.data.data);
    } catch (error) {}
  };

  const catagoriY = async (data) => {
    const header = localStorage.getItem('_tokenCode');
    if (data === 'Occasion') {
      const catagoriId = catagoriData.find((item) => item.name == 'Occasion').id;
      try {
        const response = await API.subCategoryId(catagoriId, header);
        setSearchData(response.data.data);
      } catch (error) {}
    } else if (data === 'Genre') {
      const catagoriId = catagoriData.find((item) => item.name == 'Genre').id;

      try {
        const response = await API.subCategoryId(catagoriId, header);
        setSearchData2(response.data.data);
      } catch (error) {}
    } else if (data === 'Mood') {
      const catagoriId = catagoriData.find((item) => item.name == 'Mood').id;
      try {
        const response = await API.subCategoryId(catagoriId, header);
        setSearchData3(response.data.data);
      } catch (error) {}
    }
  };

  const add_subcatagori = async (values) => {
    const header = localStorage.getItem('_tokenCode');
    setIsLoading(true);
    try {
      const reqObj = {
        name: values.name,
        mood: values.mood,
        occasion: values.occasion,
        genre: values.genre,
        description: values.description,
        music_file: imageData,
        duration: values.minutes + ':' + values.second,
        amount: values.amount,
        image: songThumb,
        filename: fileName,
        thumFilename: thumFilename,
      };
      const response = await API.add_songs(reqObj, header);
      if (response.data.success === 1) {
        MESSAGE(response.data.msg, 1);
        navigate('/song-list');
      } else {
        setIsLoading(false);
      }
    } catch (error) {}
  };

  useEffect(() => {
    get_categoryList();
  }, []);

  return (
    <Formik initialValues={initialData} validationSchema={validationSchema} onSubmit={add_subcatagori}>
      {({ setFieldValue }) => (
        <Form>
          <div className='col-lg-12 layout-spacing'>
            <div className='statbox widget box box-shadow mb-4'>
              <div className='widget-header'>
                <div className='row'>
                  <div className='col-xl-12 col-md-12 col-sm-12 col-12'>
                    <h4>Add Music</h4>
                  </div>
                </div>
              </div>
              <div className='widget-content widget-content-area'>
                <div className='row'>
                  <div className='normal'>
                    <div className='row'>
                      <div className='col-md-12'>
                        <div className='row'>
                          <div className='col-md-6'>
                            <div className='form-group position-relative mb-4'>
                              <label>
                                Title
                                <span className='text-danger'>*</span>
                              </label>
                              <Field type='text' className='form-control' placeholder='Enter here' name='name' />
                              <ErrorMessage name='name' component='div' className='text-danger error-msg' />
                            </div>
                          </div>
                          <div className='col-md-6'>
                            <div className='form-group position-relative mb-4'>
                              <label>
                                Choose Occasion
                                <span className='text-danger'>*</span>
                              </label>
                              <Field as='select' className='form-control' name='occasion' onFocus={() => catagoriY('Occasion')}>
                                <option>--- Select ---</option>
                                {searchData?.map((item, index) => (
                                  <option key={index} value={item.id}>
                                    {item.name}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage name='occasion' component='div' className='text-danger error-msg' />
                            </div>
                          </div>
                          <div className='col-md-6'>
                            <div className='form-group position-relative mb-4'>
                              <label>
                                Choose Genre
                                <span className='text-danger'>*</span>
                              </label>
                              <Field as='select' className='form-control' name='genre' onFocus={() => catagoriY('Genre')}>
                                <option>--- Select ---</option>
                                {searchData2?.map((item, index) => (
                                  <option key={index} value={item.id}>
                                    {item.name}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage name='genre' component='div' className='text-danger error-msg' />
                            </div>
                          </div>
                          <div className='col-md-6'>
                            <div className='form-group position-relative mb-4'>
                              <label>
                                Choose Mood
                                <span className='text-danger'>*</span>
                              </label>
                              <Field as='select' className='form-control' name='mood' onFocus={() => catagoriY('Mood')}>
                                <option>--- Select ---</option>
                                {searchData3?.map((item, index) => (
                                  <option key={index} value={item.id}>
                                    {item.name}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage name='mood' component='div' className='text-danger error-msg' />
                            </div>
                          </div>
                          <div className='col-md-6'>
                            <div className='form-group position-relative mb-4'>
                              <label>
                                Amount
                                <span className='text-danger'>*</span>
                              </label>
                              <Field type='number' placeholder='Enter here' className='form-control' name='amount' />
                              <ErrorMessage name='amount' component='div' className='text-danger error-msg' />
                            </div>
                          </div>
                          <div className='col-md-6'>
                            <div className='form-group position-relative mb-4'>
                              <label>
                                Music File
                                <span className='text-danger'>*</span>
                              </label>
                              <div id='dropzone'>
                                <label htmlFor='fileT' className='dz-message needsclick dropzone p-2'>
                                  <div className='icon dripicons dripicons-browser-upload'></div>
                                  <span className={imageData ? 'dz-button text-success' : 'dz-button'}>
                                    {imageData ? (
                                      <span className='d-inline-block mr-2'>
                                        <CheckCircle color='green' size='30' />
                                      </span>
                                    ) : (
                                      ''
                                    )}
                                    {imageData ? fileName : 'Upload MP3 files here'}
                                  </span>
                                  <input hidden id='fileT' type='file' onChange={(e) => imageUploading(e, setFieldValue)} className='image-preview-filepond' />
                                </label>
                              </div>
                              <ErrorMessage name='music_file' component='div' className='text-danger error-msg' />
                            </div>
                          </div>
                          <div className='col-md-6'>
                            <div className='form-group position-relative mb-4'>
                              <label>
                                Thumbnail Image
                                <span className='text-danger'>*</span>
                              </label>
                              <div id='dropzone'>
                                <label htmlFor='file' className='dz-message needsclick dropzone p-2'>
                                  <div className='icon dripicons dripicons-browser-upload'></div>
                                  <span className={songThumb ? 'dz-button text-success' : 'dz-button'}>
                                    {songThumb ? (
                                      <span className='d-inline-block mr-2'>
                                        <CheckCircle color='green' size='30' />
                                      </span>
                                    ) : (
                                      ''
                                    )}
                                    {songThumb ? thumFilename : 'Upload thumbnail files here'}
                                  </span>
                                  <input hidden id='file' type='file' onChange={(e) => imageUploadingThum(e, setFieldValue)} className='image-preview-filepond' />
                                </label>
                              </div>
                              <ErrorMessage name='thumbnail_image' component='div' className='text-danger error-msg' />
                            </div>
                          </div>
                          <div className='col-md-6'>
                            <img className='thumImg' src={songThumb} />
                          </div>
                          <div className='col-md-12'>
                            <div className='form-group position-relative mb-4'>
                              <label>
                                Description
                                <span className='text-danger'>*</span>
                              </label>
                              <Field as='textarea' name='description' placeholder='Enter here' className='form-control' rows='3' />
                              <ErrorMessage name='description' component='div' className='text-danger error-msg' />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='widget-footer'>
                {isLoading ? (
                  <button disabled type='reset' className='btn btn-info mr-2'>
                    Loading...
                  </button>
                ) : (
                  <button type='submit' className='btn btn-success mr-2'>
                    Submit
                  </button>
                )}
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddSong;
