import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
const SidBar = ({ isOpen }) => {
  return (
    <>
      <div className={isOpen ? 'anislid sidebar-wrapper sidebar-theme' : ' sidebar-wrapper sidebar-theme'}>
        <nav id='sidebar'>
          <ul className='list-unstyled menu-categories' id='accordionExample'>
            <li className='menu'>
              <NavLink to='/dashboard' className='main-item dropdown-toggle bs-tooltip'>
                <i className='las la-home'></i>
                <span>Dashboard</span>
              </NavLink>
            </li>
            <li className='menu'>
              <NavLink to='/user' className='main-item dropdown-toggle bs-tooltip' data-toggle='tooltip' data-placement='right' title='User List'>
                <i className='las la-user'></i>
                <span>User list</span>
              </NavLink>
            </li>
            <li className='menu'>
              <NavLink to='/order' className='main-item dropdown-toggle bs-tooltip' data-toggle='tooltip' data-placement='right' title='Order'>
                <i className='las la-shopping-cart'></i>
                <span>Total Order</span>
              </NavLink>
            </li>
            <li className='menu'>
              <NavLink to='/song-list' id='authPages' className='main-item dropdown-toggle bs-tooltip' data-toggle='tooltip' data-placement='right' title='Song'>
                {/* <span className='new-notification'></span> */}
                <i className='las la-music'></i>
                <span>All Songs</span>
              </NavLink>
            </li>
            <li className='menu'>
              <NavLink to='/categories' id='otherPages' className='main-item dropdown-toggle bs-tooltip' data-toggle='tooltip' data-placement='right' title='Categories'>
                <i className='las la-list'></i>
                <span>Categories</span>
              </NavLink>
            </li>
            {/* <li className="menu">
              <NavLink
                to="javascript:void(0);"
                id="basicUI"
                data-original-title="Pages"
                data-placement="right"
                className="main-item dropdown-toggle bs-tooltip"
              >
                <i className="las la-file"></i>
              </NavLink>
            </li> */}

            <li className='menu'>
              <NavLink
                to='/payment-history'
                id='forms'
                data-original-title='Transactions'
                data-placement='right'
                title='Payment History'
                className='main-item dropdown-toggle bs-tooltip'
              >
                <i className='las la-money-bill'></i>
                <span>Payment History</span>
              </NavLink>
            </li>
            {/* <li className="menu">
              <NavLink
                to="/testimonials"
                id="maps"
                data-toggle="tooltip"
                data-placement="right"
                title="testimonials"
                className="main-item dropdown-toggle bs-tooltip"
              >
                <i className="las la-cog"></i>
                Testimonials
              </NavLink>
            </li> */}
            <li className='menu'>
              <NavLink to='/about-list' id='maps' data-toggle='tooltip' data-placement='right' title='About us' className='main-item dropdown-toggle bs-tooltip'>
                <i className='bi bi-people-fill'></i>

                <span>About us</span>
              </NavLink>
            </li>
            <li className='menu'>
              <NavLink to='/homepage-list' id='maps' data-toggle='tooltip' data-placement='right' title='Homepage' className='main-item dropdown-toggle bs-tooltip'>
                <i className='bi bi-house-gear-fill'></i>

                <span>Home page</span>
              </NavLink>
            </li>
            <li className='menu'>
              <NavLink to='/faq-list' id='maps' data-toggle='tooltip' data-placement='right' title='Faq' className='main-item dropdown-toggle bs-tooltip'>
                <i className='bi bi-question-square'></i>

                <span>Faq</span>
              </NavLink>
            </li>
            <li className='menu'>
              <NavLink to='/contact-us' id='maps' data-toggle='tooltip' data-placement='right' title='Enquiry' className='main-item dropdown-toggle bs-tooltip'>
                <i className='bi bi-person-rolodex'></i>
                <span>Enquiry</span>
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
};

export default SidBar;
