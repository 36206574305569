import React from 'react';
import * as API from '../api/index';
import { useEffect } from 'react';
import { useState } from 'react';
import OrderTable from '../components/OrderTable';
import { MESSAGE, header } from '../schemas/Validation';
import { useNavigate } from 'react-router';
const Order = ({ setIsLogin }) => {
  const navigate = useNavigate();
  const [comonSataus, setComonSataus] = useState(2);
  const [data, setData] = useState([]);
  const [deleteOrderId, setDeleteOrderId] = useState(null);
  console.log('deleteOrderId: ', deleteOrderId);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    orderDataTable();
  }, []);

  const orderDataTable = async (data) => {
    try {
      const response = await API.order_data_table(data, header);
      const responseData = response.data;

      setData(responseData.data);

      if (responseData.is_login === false) {
        localStorage.removeItem('isLogin');
        setIsLogin(null);

        if (!localStorage.getItem('isLogin')) {
          navigate('/');
        }
      }
    } catch (error) {
      console.error('An error occurred while fetching order data:', error);
    }
  };

  const confirmDelete = async () => {
    try {
      const response = await API.order_delete(deleteOrderId, header);
      console.log('response: ', response);
      if (response.data.success === 1) {
        MESSAGE(response.data.msg, 1);
        orderDataTable();
        setDeleteOrderId(null);
      }
    } catch (error) {
      console.error('Error deleting FAQ', error);
    } finally {
      setShowModal(false);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <div id='tabsWithIcons' class='col-xl-12 col-lg-12 col-md-12 layout-spacing'>
        <div class='statbox widget box box-shadow'>
          <div class='widget-header'>
            <div class='row'>
              <div class='col-xl-12 col-md-12 col-sm-12 col-12'>
                <h2>Order</h2>
              </div>
            </div>
          </div>

          <div class='widget-content widget-content-area rounded-corner-pills-icon'>
            {/* <ul class='nav nav-pills mb-4 mt-3  justify-content-center d-none' id='rounded-corner-pills-icon-tab' role='tablist'>
              <li class='nav-item ml-2 mr-2' onClick={() => orderDataTable(2)}>
                <a
                  class='nav-link mb-2 active text-center'
                  id='rounded-corner-pills-icon-home-tab'
                  data-toggle='pill'
                  href='#rounded-corner-pills-icon-all'
                  role='tab'
                  aria-controls='rounded-corner-pills-icon-home'
                  aria-selected='true'
                >
                  <i class='bi bi-border-style mb-0'></i> All Orders
                </a>
              </li>
              <li class='nav-item ml-2 mr-2' onClick={() => orderDataTable(0)}>
                <a
                  class='nav-link mb-2 text-center'
                  id='rounded-corner-pills-icon-home-tab'
                  data-toggle='pill'
                  href='#rounded-corner-pills-icon-home'
                  role='tab'
                  aria-controls='rounded-corner-pills-icon-home'
                  aria-selected='true'
                >
                  <i class='las la-shopping-cart'></i> In-completed Orders
                </a>
              </li>
              <li class='nav-item ml-2 mr-2' onClick={() => orderDataTable(1)}>
                <a
                  class='nav-link mb-2 text-center'
                  id='rounded-corner-pills-icon-about-tab'
                  data-toggle='pill'
                  href='#rounded-corner-pills-icon-about'
                  role='tab'
                  aria-controls='rounded-corner-pills-icon-about'
                  aria-selected='false'
                >
                  <i class='las la-check'></i> Completed Orders
                </a>
              </li>
            </ul> */}
            <div class='tab-content' id='rounded-corner-pills-icon-tabContent'>
              <div class='tab-pane fade show active' id='rounded-corner-pills-icon-all' role='tabpanel' aria-labelledby='rounded-corner-pills-icon-home-tab'>
                <OrderTable title='ALL Order' setShowModal={setShowModal} setDeleteOrderId={setDeleteOrderId} data={data} />
              </div>
              {/* <div class='tab-pane fade show ' id='rounded-corner-pills-icon-home' role='tabpanel' aria-labelledby='rounded-corner-pills-icon-home-tab'>
                <OrderTable title='Pending Order' data={data} />
              </div>
              <div class='tab-pane fade' id='rounded-corner-pills-icon-about' role='tabpanel' aria-labelledby='rounded-corner-pills-icon-about-tab'>
                <OrderTable title='Completed Order' data={data} />
              </div> */}
            </div>
          </div>
        </div>
      </div>

      {showModal && (
        <div className='sharable-popup'>
          <div className='sharable-popup-content text-center' style={{ width: '500px' }}>
            <span className='sharable-close' onClick={closeModal}>
              &times;
            </span>
            <p style={{ margin: '20px 0', color: '#000000' }}>Are you sure you want to delete this Order?</p>
            <div className='d-flex justify-content-center align-items-center'>
              <button className='border-0 delete-btn' style={{ marginRight: '15px' }} onClick={confirmDelete}>
                Delete
              </button>
              <button className='border-0 cancel-btn' onClick={closeModal}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Order;
