import React, { useContext, useEffect, useState } from 'react';
import { CheckCircle } from 'react-feather';
import * as API from '../../api/index';
import { header } from '../../schemas/Validation';
import { useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AmountContext } from '../../components/contexts/AmountContext';

const convertToSeconds = (time) => {
  const [minutes, seconds] = time.split(':');
  return +minutes * 60 + +seconds;
};
const convertToMMSS = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
};
function formatTime(input) {
  input = input.replace(/\D/g, '');

  function pad(number) {
    return number.toString().padStart(2, '0');
  }
  if (input.length === 6) {
    const hours = input.slice(0, 2);
    const minutes = input.slice(2, 4);
    const seconds = input.slice(4);

    const validHours = Math.min(Math.max(parseInt(hours, 10), 0), 23);
    const validMinutes = Math.min(Math.max(parseInt(minutes, 10), 0), 59);
    const validSeconds = Math.min(Math.max(parseInt(seconds, 10), 0), 59);

    return `${pad(validHours)}:${pad(validMinutes)}:${pad(validSeconds)}`;
  } else if (input.length === 4) {
    const minutes = input.slice(0, 2);
    const seconds = input.slice(2);

    const validMinutes = Math.min(Math.max(parseInt(minutes, 10), 0), 59);
    const validSeconds = Math.min(Math.max(parseInt(seconds, 10), 0), 59);

    return `${pad(validMinutes)}:${pad(validSeconds)}`;
  } else if (input.length === 2) {
    const seconds = input;
    const validSeconds = Math.min(Math.max(parseInt(seconds, 10), 0), 59);

    return `${pad(validSeconds)}`;
  } else {
    return input;
  }
}

const SongTemplete = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const initialData = {
    templeteType: '',
    templeteFile: '',
    sampleData: '',
    mstart: '',
    messlength: '',
    songlength: '',
    name: '',
    amount: location.state.amount,
    // target_normalize: 0.1,
    intro_overlay_duration: 0,
    outro_overlay_duration: 0,
  };

  // ? Templete Type
  const [isTemplete, setIsTemplete] = useState(false);
  const [tampleteType, setTampleteType] = useState([]);
  const [formData, setFormData] = useState(initialData);
  const [templeteData, setTempleteData] = useState('');
  const [templeteFile, setTempleteFilea] = useState('');
  const [sampleData, setSampleData] = useState('');
  const [temFileName, setTemFileName] = useState('');
  const [samFile, setSamFile] = useState('');
  const [errors, setErrors] = useState({
    name: '',
    amount: '',
    mstart: '',
    messlength: '',
    songlength: '',
  });

  const uploadtemplete = (e) => {
    try {
      let file = e.target.files[0];

      if (!file || !file.type.includes('audio/mpeg')) {
        toast.error('Please upload only MP3 audio file.');
        return;
      }

      const tIndex = file.name.indexOf('T');
      if (tIndex !== -1 && !isNaN(file.name[tIndex + 1]) && file.name.includes('_')) {
        try {
          const [mstart, messlengthWithExt] = file.name.split('T')[1].split('_');
          const messlength = convertToMMSS(messlengthWithExt.split('.')[0]);
          const mstartMMSS = convertToMMSS(mstart);

          setFormData((prevData) => ({
            ...prevData,
            mstart: mstartMMSS,
            messlength,
          }));
        } catch (error) {
          console.error('Error processing file name:', error);
        }
      }

      setTemFileName(file.name);
      var reader = new FileReader();
      reader.onloadend = function () {
        setTempleteFilea(reader.result);
      };
      reader.readAsDataURL(file);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const uploadSample = (e) => {
    let file = e.target.files[0];

    if (file?.type !== 'audio/mpeg') {
      toast.error('Please upload only MP3 audio file.');
      return;
    }
    setSamFile(file.name);
    var reader = new FileReader();
    reader.onloadend = function () {
      setSampleData(reader.result);
    };
    reader.readAsDataURL(file);
    setErrors((prevErrors) => ({
      ...prevErrors,
      sampleData: '',
    }));
  };

  const handalerChanges = (e) => {
    const { name, value } = e.target;
    console.log('name: ', name);

    let formattedValue = value;
    let errorMessage = '';

    if (name === 'mstart' || name === 'messlength' || name === 'songlength') {
      formattedValue = value.length > 2 ? formatTime(value) : value;
      if (formattedValue.length < 4) {
        errorMessage = 'Value needs to be in this particular format. MM:SS';
      }
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMessage,
    }));

    setFormData((prevData) => ({
      ...prevData,
      [name]: formattedValue,
    }));
  };

  const addTemplete = async () => {
    setIsTemplete(true);

    // Validate 'name', 'templateFile', and 'sampleData'
    let hasErrors = false;
    let newErrors = {};

    if (!formData.name) {
      newErrors.name = 'Name is required.';
      hasErrors = true;
    }
    if (!templeteFile) {
      newErrors.templeteFile = 'Voice-Over Template is required.';
      hasErrors = true;
    }
    if (!sampleData) {
      newErrors.sampleData = 'Upload Prerecorded File is required.';
      hasErrors = true;
    }
    if (templeteData === 'Intro-Middle-Outro' && !formData.messlength) {
      newErrors.messlength = 'Middle Start is required.';
      hasErrors = true;
    }
    if (templeteData === 'Intro-Middle-Outro' && !formData.mstart) {
      newErrors.mstart = 'Message duration is required.';
      hasErrors = true;
    }
    if (!formData.songlength) {
      newErrors.songlength = 'Duration Of Song is required.';
      hasErrors = true;
    }

    setErrors(newErrors);
    if (hasErrors) {
      setIsTemplete(false);
      return;
    }

    // if (Object.values(newErrors).some((error) => error !== '')) {
    //   toast.error('Please fix the errors before submitting.');
    //   setIsTemplete(false);
    //   return;
    // }

    if (templeteData === 'Intro-Middle-Outro') {
      try {
        const reqObj = {
          name: formData.name,
          temFileName: temFileName,
          samFile: samFile,
          templeteType: templeteData,
          templateFile: templeteFile,
          sampleData: sampleData,
          mstart: convertToSeconds(formData.mstart),
          messlength: convertToSeconds(formData.messlength),
          songlength: convertToSeconds(formData.songlength),
          song: location.state.id,
          amount: parseFloat(formData.amount).toFixed(2) || parseFloat(location.state.amount).toFixed(2),
          // target_normalize: formData.target_normalize,
          intro_overlay_duration: formData.intro_overlay_duration || 0,
          outro_overlay_duration: formData.outro_overlay_duration || 0,
        };
        const response = await API.song_templeteAdd(reqObj, header);
        if (response.data.success === 1) {
          setTempleteFilea('');
          setSampleData('');
          navigate('/song-list');
          setIsTemplete(false);
        } else if (response.data.success === 0) {
          toast.error('The field cannot be left blank. Please fill in this field.');
          setIsTemplete(false);
        } else {
          setIsTemplete(false);
        }
      } catch (error) {
        setIsTemplete(false);
      }
    } else {
      try {
        const reqObj = {
          name: formData.name,
          temFileName: temFileName,
          samFile: samFile,
          templeteType: templeteData,
          templateFile: templeteFile,
          sampleData: sampleData,
          songlength: convertToSeconds(formData.songlength),
          song: location.state.id,
          // target_normalize: formData.target_normalize,
          intro_overlay_duration: formData.intro_overlay_duration || 0,
          outro_overlay_duration: formData.outro_overlay_duration || 0,
          amount: parseFloat(formData.amount).toFixed(2) || parseFloat(location.state.amount).toFixed(2),
        };
        const response = await API.song_templeteAdd(reqObj, header);
        if (response.data.success === 1) {
          setTempleteFilea('');
          setSampleData('');
          navigate('/song-list');
          setIsTemplete(false);
        }
      } catch (error) {
        console.log('error: ', error);
        setIsTemplete(false);
      }
    }
  };

  const getAlltemplete = async () => {
    try {
      const response = await API.getTempleteType(header);
      setTampleteType(response.data.data);
    } catch (error) {}
  };

  const templeteType = (e) => {
    const typeData = e.target.value;
    setTempleteData(typeData);
    setTempleteFilea('');
    setSampleData('');
    if (typeData === 'Intro-Middle-Outro') {
      setTempleteData('Intro-Middle-Outro');
    } else if (typeData === 'Happy birthday') {
      setTempleteData('Happy birthday');
    } else if (typeData === 'Intro-Outro') {
      setTempleteData('Intro-Outro');
    }
  };

  useEffect(() => {
    getAlltemplete();
  }, []);

  return (
    <>
      <div class='col-lg-12 layout-spacing'>
        <div class='statbox widget box box-shadow mb-4'>
          <div class='widget-header'>
            <div class='row'>
              <div class='col-xl-9 col-md-9 col-sm-9 col-12'>
                <h4>Add Voice Over Template</h4>
              </div>
              <div class='col-xl-3 col-md-3 col-sm-3 col-12 text-right'>
                <Link state={{ id: location.state.id }} to='/template-list' class='btn btn-success mr-2'>
                  View Added Template
                </Link>
              </div>
            </div>
          </div>

          <div class='widget-content widget-content-area'>
            <div className='row'>
              <div className='col-md-9 borderUS'>
                <div className='normal'>
                  <div className='justify-content-center row'>
                    <div className='col-md-6'>
                      <div class='form-group'>
                        <label>
                          Select Template Type
                          <span class='text-danger'>*</span>
                        </label>
                        <select className='form-control' onChange={templeteType}>
                          <option>--- Select Template---</option>
                          {tampleteType.map((item, index) => (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  {templeteData === 'Intro-Middle-Outro' ? (
                    <>
                      <div className='row'>
                        <div className='col-md-4'>
                          <div class='form-group'>
                            <label>
                              Title
                              <span class='text-danger'>*</span>
                            </label>
                            <input type='text' class='form-control' placeholder='Enter here' value={formData.name} name='name' onChange={handalerChanges} />
                            {errors.name && <div class='text-danger'>{errors.name}</div>}
                          </div>
                        </div>
                        <div className='col-md-4'>
                          <div class='form-group'>
                            <label>
                              Voice-Over Template
                              <span class='text-danger'>*</span>
                            </label>
                            <div id='dropzone'>
                              <form encType='multipart/form-data' action='/upload' class='dropzone needsclick dz-clickable'>
                                <label for='file' className='dz-message needsclick'>
                                  <div class='icon dripicons dripicons-browser-upload'></div>{' '}
                                  <form encType='multipart/form-data'>
                                    <span class={templeteFile ? 'dz-button text-success' : 'dz-button'}>
                                      {templeteFile ? (
                                        <span className='d-inline-block mr-2'>
                                          <CheckCircle color='green' size='30' />
                                        </span>
                                      ) : (
                                        ''
                                      )}

                                      {templeteFile ? temFileName : 'Upload Voice Over files here'}
                                    </span>
                                    <input hidden id='file' type='file' onChange={uploadtemplete} class='image-preview-filepond' />
                                  </form>
                                </label>
                              </form>
                              {errors.templeteFile && <div class='text-danger'>{errors.templeteFile}</div>}
                            </div>
                          </div>
                        </div>
                        <div className='col-md-4'>
                          <div class='form-group'>
                            <label>
                              Upload Prerecorded File
                              <span class='text-danger'>*</span>
                            </label>
                            <div id='dropzone'>
                              <form encType='multipart/form-data' action='/upload' class='dropzone needsclick dz-clickable'>
                                <label for='files' className='dz-message needsclick'>
                                  <div class='icon dripicons dripicons-browser-upload'></div>{' '}
                                  <form encType='multipart/form-data'>
                                    <span class={sampleData ? 'dz-button text-success' : 'dz-button'}>
                                      {sampleData ? (
                                        <span className='d-inline-block mr-2'>
                                          <CheckCircle color='green' size='30' />
                                        </span>
                                      ) : (
                                        ''
                                      )}

                                      {sampleData ? samFile : 'Upload Sample files here'}
                                    </span>
                                    <input hidden id='files' type='file' onChange={uploadSample} class='image-preview-filepond' />
                                  </form>
                                </label>
                              </form>
                              {errors.sampleData && <div class='text-danger'>{errors.sampleData}</div>}
                            </div>
                          </div>
                        </div>

                        <div className='col-md-4'>
                          <div class='form-group'>
                            <label>
                              Middle Start
                              <span class='text-danger'>*</span>
                            </label>
                            <input type='text' class='form-control' placeholder='MM:SS' value={formData.mstart} name='mstart' onChange={handalerChanges} maxLength={5} />
                            {errors.mstart && <div class='text-danger'>{errors.mstart}</div>}
                          </div>
                        </div>

                        <div className='col-md-4'>
                          <div class='form-group'>
                            <label>
                              Message duration
                              <span class='text-danger'>*</span>
                            </label>
                            <input type='text' class='form-control' placeholder='MM:SS' value={formData.messlength} name='messlength' onChange={handalerChanges} maxLength={5} />
                            {errors.messlength && <div class='text-danger'>{errors.messlength}</div>}
                          </div>
                        </div>

                        <div className='col-md-4'>
                          <div class='form-group'>
                            <label>
                              Duration Of Song
                              <span class='text-danger'>*</span>
                            </label>
                            <input type='text' class='form-control' placeholder='MM:SS' value={formData.songlength} name='songlength' onChange={handalerChanges} maxLength={5} />
                            {errors.songlength && <div class='text-danger'>{errors.songlength}</div>}
                          </div>
                        </div>
                        {/* <div className='col-md-4'>
                          <div class='form-group'>
                            <label>
                              Normalize Song
                              <span class='text-danger'>*</span>
                            </label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter here'
                              value={formData.target_normalize}
                              name='target_normalize'
                              onChange={(e) => {
                                if (isNaN(e.target.value)) {
                                  return false;
                                } else {
                                  handalerChanges(e);
                                }
                              }}
                            />
                          </div>
                        </div> */}
                        <div className='col-md-4'>
                          <div class='form-group'>
                            <label>
                              Intro Overlay Duration
                              <span class='text-danger'>*</span>
                            </label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter here'
                              value={formData.intro_overlay_duration}
                              name='intro_overlay_duration'
                              onChange={(e) => {
                                if (isNaN(e.target.value)) {
                                  return false;
                                } else {
                                  handalerChanges(e);
                                }
                              }}
                            />
                          </div>
                        </div>
                        <div className='col-md-4'>
                          <div class='form-group'>
                            <label>
                              Outro Overlay Duration
                              <span class='text-danger'>*</span>
                            </label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter here'
                              value={formData.outro_overlay_duration}
                              name='outro_overlay_duration'
                              onChange={(e) => {
                                if (isNaN(e.target.value)) {
                                  return false;
                                } else {
                                  handalerChanges(e);
                                }
                              }}
                            />
                          </div>
                        </div>

                        <div className='col-md-4'>
                          <div class='form-group'>
                            <label>
                              Amount
                              <span class='text-danger'>*</span>
                            </label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter here'
                              value={formData.amount}
                              name='amount'
                              onChange={(e) => {
                                if (isNaN(e.target.value)) {
                                  return false;
                                } else {
                                  handalerChanges(e);
                                }
                              }}
                            />
                            {/* {errors.amount && <div class='text-danger'>{errors.amount}</div>} */}
                          </div>
                        </div>

                        <div className='col-md-12'>
                          {isTemplete ? (
                            <button class='btn btn-info mr-2'>loading ....</button>
                          ) : (
                            <button onClick={addTemplete} class='btn btn-success mr-2'>
                              Add Template
                            </button>
                          )}
                        </div>
                      </div>
                    </>
                  ) : templeteData === 'Happy birthday' || templeteData === 'Intro-Outro' ? (
                    <>
                      <div className='row'>
                        <div className='col-md-4'>
                          <div class='form-group'>
                            <label>
                              Title
                              <span class='text-danger'>*</span>
                            </label>
                            <input type='text' class='form-control' placeholder='Enter here' value={formData.name} name='name' onChange={handalerChanges} />
                            {errors.name && <div class='text-danger'>{errors.name}</div>}
                          </div>
                        </div>
                        <div className='col-md-4'>
                          <div class='form-group'>
                            <label>
                              Voice-Over Template
                              <span class='text-danger'>*</span>
                            </label>
                            <div id='dropzone'>
                              <form encType='multipart/form-data' action='/upload' class='dropzone needsclick dz-clickable'>
                                <label for='file' className='dz-message needsclick'>
                                  <div class='icon dripicons dripicons-browser-upload'></div>{' '}
                                  <form encType='multipart/form-data'>
                                    <span class={templeteFile ? 'dz-button text-success' : 'dz-button'}>
                                      {templeteFile ? (
                                        <span className='d-inline-block mr-2'>
                                          <CheckCircle color='green' size='30' />
                                        </span>
                                      ) : (
                                        ''
                                      )}

                                      {templeteFile ? temFileName : 'Upload Voice Over files here'}
                                    </span>
                                    <input hidden id='file' type='file' onChange={uploadtemplete} class='image-preview-filepond' />
                                  </form>
                                </label>
                              </form>
                              {errors.templeteFile && <div class='text-danger'>{errors.templeteFile}</div>}
                            </div>
                          </div>
                        </div>
                        <div className='col-md-4'>
                          <div class='form-group'>
                            <label>
                              Upload Prerecorded File
                              <span class='text-danger'>*</span>
                            </label>
                            <div id='dropzone'>
                              <form encType='multipart/form-data' action='/upload' class='dropzone needsclick dz-clickable'>
                                <label for='files' className='dz-message needsclick'>
                                  <div class='icon dripicons dripicons-browser-upload'></div>{' '}
                                  <form encType='multipart/form-data'>
                                    <span class={sampleData ? 'dz-button text-success' : 'dz-button'}>
                                      {sampleData ? (
                                        <span className='d-inline-block mr-2'>
                                          <CheckCircle color='green' size='30' />
                                        </span>
                                      ) : (
                                        ''
                                      )}

                                      {sampleData ? samFile : 'Upload Sample files here'}
                                    </span>
                                    <input hidden id='files' type='file' onChange={uploadSample} class='image-preview-filepond' />
                                  </form>
                                </label>
                              </form>
                              {errors.sampleData && <div class='text-danger'>{errors.sampleData}</div>}
                            </div>
                          </div>
                        </div>
                        <div className='col-md-4'>
                          <div class='form-group'>
                            <label>
                              Duration Of Song
                              <span class='text-danger'>*</span>
                            </label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='MM:SS'
                              value={formData.songlength}
                              name='songlength'
                              onChange={(e) => handalerChanges(e)}
                              maxLength={5}
                            />
                            {errors.songlength && <div class='text-danger'>{errors.songlength}</div>}
                          </div>
                        </div>

                        {/* <div className='col-md-4'>
                          <div class='form-group'>
                            <label>
                              Normalize Song
                              <span class='text-danger'>*</span>
                            </label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter here'
                              value={formData.target_normalize}
                              name='target_normalize'
                              onChange={(e) => {
                                if (isNaN(e.target.value)) {
                                  return false;
                                } else {
                                  handalerChanges(e);
                                }
                              }}
                            />
                          </div>
                        </div> */}
                        <div className='col-md-4'>
                          <div class='form-group'>
                            <label>
                              Intro Overlay Duration
                              <span class='text-danger'>*</span>
                            </label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter here'
                              value={formData.intro_overlay_duration}
                              name='intro_overlay_duration'
                              onChange={(e) => {
                                if (isNaN(e.target.value)) {
                                  return false;
                                } else {
                                  handalerChanges(e);
                                }
                              }}
                            />
                          </div>
                        </div>
                        <div className='col-md-4'>
                          <div class='form-group'>
                            <label>
                              Outro Overlay Duration
                              <span class='text-danger'>*</span>
                            </label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter here'
                              value={formData.outro_overlay_duration}
                              name='outro_overlay_duration'
                              onChange={(e) => {
                                if (isNaN(e.target.value)) {
                                  return false;
                                } else {
                                  handalerChanges(e);
                                }
                              }}
                            />
                          </div>
                        </div>
                        <div className='col-md-4'>
                          <div class='form-group'>
                            <label>
                              Amount
                              <span class='text-danger'>*</span>
                            </label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter here'
                              value={formData.amount}
                              name='amount'
                              onChange={(e) => {
                                if (isNaN(e.target.value)) {
                                  return false;
                                } else {
                                  handalerChanges(e);
                                }
                              }}
                            />
                          </div>
                        </div>
                        <div className='col-md-12'>
                          {isTemplete ? (
                            <button class='btn btn-info mr-2'>loading ....</button>
                          ) : (
                            <button onClick={addTemplete} class='btn btn-success mr-2'>
                              Add Template
                            </button>
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SongTemplete;
