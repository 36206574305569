import React, { useEffect, useState } from 'react';
import { Edit2, PlusCircle, Trash2 } from 'react-feather';
import * as API from '../api/index';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { header } from '../schemas/Validation';
import { IMG } from '../api/constant';

const Faq1 = ({ setIsLogin }) => {
  const navigate = useNavigate();
  const [faqData, setFaqData] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    fetchFaqData();
  }, []);

  const fetchFaqData = async () => {
    const token = localStorage.getItem('_tokenCode');
    try {
      const response = await API.getFaqList(token);
      setFaqData(response.data.data);
      // if (!response.data.is_login) {
      //   handleLogout();
      // }
    } catch (error) {
      console.error('Error fetching FAQ data', error);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('isLogin');
    setIsLogin(false);
    navigate('/');
  };

  const handleDelete = (id) => {
    setDeleteId(id);
    setShowModal(true);
  };

  const confirmDelete = async () => {
    try {
      const response = await API.delete_faq(deleteId, header);
      if (response.data.success === 1) {
        fetchFaqData();
        toast.success(response.data.msg, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setDeleteId(null);
      }
    } catch (error) {
      console.error('Error deleting FAQ', error);
    } finally {
      setShowModal(false);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing'>
      <div className='widget ecommerce-table'>
        <div className='widget-heading'>
          <div className='row'>
            <div className='col-xl-10 col-md-10 col-sm-10 col-12'>
              <h5>List of FAQs</h5>
            </div>
            <div className='col-md-2 text-lg-right'>
              <Link to='/add-faq' className='btn btn-success font-20'>
                <PlusCircle size={20} />
              </Link>
            </div>
          </div>
        </div>
        <div className='widget-content'>
          <div className='table-responsive'>
            <table className='table table-hover table-bordered'>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Questions</th>
                  <th>Answers</th>
                  <th className='text-center'>Action</th>
                </tr>
              </thead>
              <tbody>
                {faqData?.length === 0 ? (
                  <tr className='text-center'>
                    <td colSpan='4' style={{ fontSize: '25px' }}>
                      No Record found
                    </td>
                  </tr>
                ) : (
                  faqData?.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.question}</td>
                      <td className='w-50' dangerouslySetInnerHTML={{ __html: item.answer }}></td>
                      <td className='d-flex justify-content-center'>
                        <Link to='/edit-faq' state={{ id: item.id }} className='btn btn-success d-flex align-items-center font-20 px-2 mr-2'>
                          <Edit2 size={20} />
                        </Link>
                        <button type='button' onClick={() => handleDelete(item.id)} className='btn btn-danger d-flex align-items-center font-20 px-2'>
                          <Trash2 size={20} />
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Bootstrap Modal for Confirmation */}
      {showModal && (
        <div className='sharable-popup'>
          <div className='sharable-popup-content text-center' style={{ width: '500px' }}>
            <span className='sharable-close' onClick={closeModal}>
              &times;
            </span>
            <p style={{ margin: '20px 0', color: '#000000' }}>Are you sure you want to delete this FAQ?</p>
            <div className='d-flex justify-content-center align-items-center'>
              <button className='border-0 delete-btn' style={{ marginRight: '15px' }} onClick={confirmDelete}>
                Delete
              </button>
              <button className='border-0 cancel-btn' onClick={closeModal}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Faq1;
