import axios from 'axios';
import * as c from '../api/constant';

// ? REGISTRATION API
export const user_registration = async (data) => {
  try {
    const url = c.SIGNUP + '/login';
    const res = await axios.post(url, data);
    return res;
  } catch (e) {
    return e.response;
  }
};

// ? USER LIST SHOW
export const user_listing = async (header) => {
  try {
    const url = c.SIGNUP + '/get-users';
    const res = await axios.get(url, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};

export const get_user_by_id = async (id, header) => {
  try {
    const url = `${c.SIGNUP}/get-users/${id}`;
    const res = await axios.get(url, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};

export const update_user = async (id, data, header) => {
  try {
    const url = `${c.SIGNUP}/update_user/${id}`;
    const res = await axios.patch(url, data, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};

// ? USER LIST SHOW
export const user_status = async (data, header) => {
  try {
    const url = c.SIGNUP + '/user-status-change';
    const res = await axios.post(url, data, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};

export const user_delete = async (data) => {
  try {
    const url = c.SIGNUP + '/delete-user/' + data;
    const res = await axios.delete(url);
    return res;
  } catch (e) {
    return e.response;
  }
};

export const get_subCategory = async (header) => {
  try {
    const url = c.CATAGORIES;
    const res = await axios.get(url, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};

export const add_subCategory = async (data, header) => {
  try {
    const url = c.SUBCATAGORIES;
    const res = await axios.post(url, data, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};

export const getMain_subCategory = async (header) => {
  try {
    const url = c.SUBCATAGORIES;
    const res = await axios.get(url, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};

export const subCategoryId = async (data, header) => {
  try {
    const url = c.SUBCATAGORIES + '/categorywise/' + data;
    const res = await axios.get(url, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};

export const subCategoryId_delete = async (data, header) => {
  try {
    const url = c.SUBCATAGORIES + '/' + data;
    const res = await axios.delete(url, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};

export const subCategoryId_editGet = async (data, header) => {
  try {
    const url = c.SUBCATAGORIES + '/' + data;
    const res = await axios.get(url, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};

export const subCategoryId_edit = async (data, header) => {
  try {
    const url = c.SUBCATAGORIES + '/' + data.id;
    const res = await axios.patch(url, data, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};
export const add_songs = async (data, header) => {
  try {
    const url = c.SONGS;
    const res = await axios.post(url, data, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};
export const song_listing = async (header) => {
  try {
    const url = c.SONGS;
    const res = await axios.get(url, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};

export const template_listing = async (data, header) => {
  try {
    const url = c.GETSONGTEMPLETE + '/' + data;
    const res = await axios.get(url, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};
export const dashboardData = async () => {
  try {
    const url = c.URL + '/dashboard-counts';

    const res = await axios.get(url);
    return res;
  } catch (e) {
    return e.response;
  }
};
export const song_catagoriId = async (header) => {
  try {
    const url = c.SONGS;
    const res = await axios.get(url, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};

export const song_delete = async (data, header) => {
  try {
    const url = c.SONGS + '/' + data;
    const res = await axios.delete(url, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};
export const patch_autostop_song = async (data, header) => {
  try {
    const url = c.URL + '/autostop';
    const res = await axios.patch(url, data, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};
export const get_autostop_song = async (header) => {
  try {
    const url = c.URL + '/autostop';
    const res = await axios.get(url, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};

export const tem_delete = async (data, header) => {
  try {
    const url = c.DELETETEMPLETE + '/' + data;
    const res = await axios.delete(url, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};

export const song_templeteAdd = async (data, header) => {
  try {
    const url = c.SONGTEMPLETE;
    const res = await axios.post(url, data, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};

export const getTempleteType = async (header) => {
  try {
    const url = c.TEMPLETETYPE;
    const res = await axios.get(url, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};

export const add_script = async (data, header) => {
  try {
    const url = c.GUIDE;
    const res = await axios.post(url, data, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};
export const script_listing = async (header) => {
  try {
    const url = c.GUIDE;
    const res = await axios.get(url, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};
export const script_delete = async (data, header) => {
  try {
    const url = c.GUIDE + '/' + data;
    const res = await axios.delete(url, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};

export const moodTagSearchApi = async (data, header) => {
  try {
    const url = c.SUBCATAGORIES + '/categorywise-search';
    const res = await axios.post(url, data, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};
export const order_data_table = async (data, header) => {
  try {
    const url = c.ORDER;
    const res = await axios.get(url, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};

export const order_delete = async (data, header) => {
  try {
    const url = c.SIGNUP + '/delete-order/' + data;
    const res = await axios.delete(url, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};

export const payment_history = async (header) => {
  try {
    const url = c.SIGNUP + '/paymenthistory';
    const res = await axios.get(url, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};
export const getSongByid = async (data, header) => {
  try {
    const url = c.SIGNUP + '/getsong/' + data;
    const res = await axios.get(url, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};
export const update_songs = async (data, header) => {
  try {
    const url = c.SIGNUP + '/updatesong/' + data.id;
    const res = await axios.patch(url, data, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};
export const getTempleteTypeId = async (data, header) => {
  try {
    const url = c.SIGNUP + '/getTemplate/' + data;

    const res = await axios.get(url, data, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};

export const updateTamplete = async (data, header) => {
  try {
    const url = c.SIGNUP + '/updatetemplate/' + data.templeteId;
    const res = await axios.patch(url, data, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};
export const gettempleteById = async (data, header) => {
  try {
    const url = c.SIGNUP + '/gettemplatebytype/' + data.tempId + '/' + data.tempTypeId;
    const res = await axios.get(url, data, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};
export const aboutus = async (data, header) => {
  try {
    const url = c.SIGNUP + '/aboutus';
    const res = await axios.post(url, data, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};

export const aboutusDelete = async (data, header) => {
  try {
    const url = c.SIGNUP + '/aboutus/' + data;
    const res = await axios.delete(url, data, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};

export const aboutusGet = async (header) => {
  try {
    const url = c.SIGNUP + '/aboutus';
    const res = await axios.get(url, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};
export const aboutusUpdate = async (data, header, id) => {
  try {
    const url = c.SIGNUP + '/aboutus/' + id;
    const res = await axios.patch(url, data, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};

export const contactGet = async (header) => {
  try {
    const url = c.CONTACTUS + '/contactus';
    const res = await axios.get(url, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};

export const deleteEnquiryContact = async (id, header) => {
  try {
    const url = `${c.CONTACTUS}/contactus/${id}`;
    const res = await axios.delete(url, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    console.error('Error deleting order:', e);
    return e.response;
  }
};

export const getFaq_id = async (id, header) => {
  try {
    const url = c.URL + `/faq/${id}`;
    const res = await axios.get(url, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};

export const add_faq = async (data, header) => {
  try {
    const url = c.URL + '/addfaq';
    const res = await axios.post(url, data, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};
export const edit_faq = async (id, data, header) => {
  try {
    const url = c.URL + `/faq/${id}`;
    console.log('url: ', url);
    const res = await axios.patch(url, data, {
      headers: JSON.parse(header),
    });
    console.log('res: ', res);
    return res;
  } catch (e) {
    return e.response;
  }
};

export const getFaqList = async (header) => {
  try {
    const url = c.URL + '/faq';
    const res = await axios.get(url, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};

export const delete_faq = async (id, header) => {
  try {
    const url = c.URL + `/faq/${id}`;
    const res = await axios.delete(url, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};

export const homepage = async (data, header) => {
  try {
    const url = c.SIGNUP + '/homepage';
    const res = await axios.post(url, data, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};

export const Gethomepage = async (header) => {
  try {
    const url = c.SIGNUP + '/homepage';
    const res = await axios.get(url, {
      headers: JSON.parse(header),
    });
    console.log(res, 'res');
    return res;
  } catch (e) {
    return e.response;
  }
};
export const Updatehomepage = async (data, header, id) => {
  try {
    const url = c.SIGNUP + '/homepage/' + id;
    const res = await axios.patch(url, data, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};

export const allCountry = async (header) => {
  try {
    const url = c.URL + '/getcountry';
    const res = await axios.get(url, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};
export const Country_id = async (data, header) => {
  try {
    const url = c.URL + '/country/' + data;

    const res = await axios.get(url, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};
export const allState = async (data, header) => {
  try {
    const url = c.URL + '/country/' + data + '/getstate';

    const res = await axios.get(url, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};
export const allCity = async (data, header) => {
  try {
    const url = c.URL + '/state/' + data + '/getcities';
    const res = await axios.get(url, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};
